import * as React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import Typography from '../Typography';
import blue from '../colors/blue';
import { typographyDefaultTheme } from '../index';

const styles = (theme) => ({
  root: {
    transition: 'background-color 0.2s ease',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    '&:hover:not($selected):not($disabled)': {
      color: blue[90],
    },
  },
  icon: {
    'svg&': {
      width: '1em',
      height: '1em',
    },
  },
  label: {},
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    '& $icon ~ $label': {
      marginLeft: '15px',
    },
    '& $label ~ $icon': {
      marginLeft: '16px',
    },
  },
  selected: {
    ...typographyDefaultTheme.typography.subtitle,
    color: blue[100],
  },
  disabled: {
    ...typographyDefaultTheme.typography.disabled,
  },
});
const TextWithIcon = React.forwardRef((props, ref) => {
  const {
    icon: propsIcon,
    children,
    label: propsLabel,
    iconFirst,
    classes,
    selected,
    className,
    variant = 'common',
    disabled,
  } = props;
  const icon = propsIcon ? React.cloneElement(propsIcon, {
    ...propsIcon.props,
    className: clsx(classes.icon, propsIcon.props?.className),
  }) : undefined;
  const label = <div className={classes.label}>{propsLabel}</div>;
  return (
    <div
      className={clsx(classes.root, { [classes.selected]: selected }, { [classes.disabled]: disabled }, className)}
      ref={ref}
    >
      <Typography>
        {children || (iconFirst ? (
          <div className={classes.wrapper}>
            {' '}
            {icon}
            {label}
          </div>
        )
          : (
            <div className={classes.wrapper}>
              {label}
              {icon}
            </div>
          ))}
      </Typography>
    </div>
  );
});

export default withStyles(styles)(TextWithIcon);
