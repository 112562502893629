import { withStyles } from '@material-ui/styles';
import { InputAdornment, InputBase } from '@material-ui/core';
import React, { useRef } from 'react';
import classNames from 'classnames';
import blue from '../colors/blue';
import grey from '../colors/grey';
import red from '../colors/red';
import { typographyDefaultTheme } from '../Typography';
import { CloseIcon } from '../icons';
import { Typography } from '../index';
import { greyColor } from '../colors';

const styles = (theme) => ({
  root: theme.root,
  adornment: theme.adornment,
  error: theme.error,
  inputDisabled: theme.inputDisabled,
  input: theme.input,
  filled: theme.filled,
  placeholder: theme.placeholder,
  hover: defaultTheme.hover,
  focused: theme.focused,
  errorText: theme.errorText,
  closeIcon: theme.closeIcon,
  label: theme.label,
});

const defaultTheme = {
  root: {
    width: '100%',
    transition: 'background-color 0.2s, box-shadow 0.2s, color 0.2s, border-color 0.2s',
    height: '45px',
    'label + &': {
      marginTop: '3px',
    },
    paddingLeft: '15px',
    paddingRight: '15px',
    paddingTop: '14px',
    paddingBottom: '14px',
    borderRadius: 4,
    position: 'relative',
    borderColor: grey[50],
    backgroundColor: 'white',
    border: '1px solid #ced4da',
    fontSize: 16,
    // width: 'auto',
    padding: '10px 12px',
  },
  adornment: {
    color: grey[50],
  },
  error: {
    borderColor: red[100],
    color: red[100],
  },
  inputDisabled: {
    background: grey[10],
    '&': {
      border: `1px solid ${grey[25]}`,
    },
  },
  input: {
    ...typographyDefaultTheme.typography.root,
    ...typographyDefaultTheme.typography.common,
  },
  filled: {
    '& input:not(:placeholder-shown)': {
      borderColor: grey[80],
      '& svg': {
        color: grey[80],
      },
    },
  },
  placeholder: {
    '& > $input::placeholder': {
      ...typographyDefaultTheme.typography.root,
      ...typographyDefaultTheme.typography.disabled,
      opacity: '1',
    },
    '&$error > $input::placeholder': {
      color: red[100],
    },
  },
  hover: {
    '&:hover:not($inputDisabled):not($error):not(:focus-within)': {
      borderColor: grey[80],
      '&:hover > $input::placeholder': {
        color: grey[50],
      },
      '& svg': {
        color: grey[80],
      },
    },

  },
  focused: {
    '&:not($error)': {
      borderColor: blue[100],
      '& $adornment svg': {
        color: grey[100],
      },
    },
  },

  errorText: {
    marginTop: '5px',
    color: red[100],
  },
  closeIcon: {
    cursor: 'pointer',
    color: red[100],
  },
  label: {
    marginBottom: '5px',
    color: greyColor[80],
    ...typographyDefaultTheme.typography.title,
    ...typographyDefaultTheme.typography.common,
  },
  disabled: {
    '&$root': {
      backgroundColor: grey[25],
    },
  },
};

const Input = React.forwardRef((props, ref) => {
  const {
    classes,
    head,
    icon,
    label,
    disabled,
    className,
    placeholder,
    errorText,
    ...other
  } = props;
  const {
    adornment,
    label: labelClass,
    closeIcon,
    inputDisabled,
    filled,
    placeholder: placeholderClass,
    hover,
    errorText: errorTextClass,
    ...otherClasses
  } = classes;
  const textInput = useRef(null);
  const clearInput = () => {
    textInput.current.value = '';
    if (other.onChange) {
      other.onChange({ target: { value: '' } });
    }
  };

  const getAdornment = () => (errorText ? (
    <InputAdornment position="end">
      <CloseIcon
        className={classes.closeIcon}
        onClick={() => {
          clearInput(textInput);
        }}
      />
    </InputAdornment>
  ) : icon ? (
    <span
      onClick={() => {
        textInput.current.focus();
      }}
      className={classes.adornment}
    >
      {icon}
    </span>
  ) : undefined);
  return (
    <div className={className}>
      <div className={classes.label}>
        {label
            && (
            <Typography>
              {label}
            </Typography>
            )}
      </div>
      <InputBase
        ref={ref}
        disabled={disabled}
        inputRef={textInput}
        error={Boolean(errorText)}
        placeholder={placeholder}
        className={classNames(disabled ? classes.inputDisabled : undefined, classes.filled, classes.placeholder, classes.hover)}
        endAdornment={
                getAdornment(errorText, textInput)
}
        {...other}
        classes={otherClasses}
      />
      {errorText
        && (
        <div>
          <ErrorText errorText={errorText} />
        </div>
        )}
    </div>
  );
});

const ErrorText = withStyles(styles, { name: 'MuiInputBase' })((props) => {
  const { errorText, classes } = props;
  return (
    <Typography
      classes={{ root: classes.errorText }}
    >
      { errorText }
    </Typography>
  );
});

export default withStyles(styles, { name: 'MuiInputBase', defaultTheme })(Input);
