import { Chip as MuiChip, withStyles } from '@material-ui/core';
import * as React from 'react';
import { typographyDefaultTheme } from '../Typography';
import blue from '../colors/blue';
import { CloseIcon } from '../icons';
import grey from '../colors/grey';

const defaultTheme = {
  root: {
    transition: 'background-color 0.2s, box-shadow 0.2s, color 0.2s, border-color 0.2s',
    height: '45px',
    backgroundColor: blue[15],
    color: blue[100],
    borderRadius: '5px',
    minHeight: 'auto',
    paddingRight: '15px',
    '&:focus': {
      backgroundColor: blue[15],
    },
    '&:not($disabled):hover': {
      color: blue[90],
    },
  },
  labelSmall: {
    '&$label': {
      ...typographyDefaultTheme.typography.detailed,
    },
  },
  sizeSmall: {
    height: '25px',
  },
  deleteIconSmall: {
    '&$deleteIcon': {
      height: '6.56px',
      width: '6.56px',
    },
  },
  disabled: {
    '&$root': {
      opacity: 1,

      backgroundColor: grey[10],
      color: grey[25],
      '& svg': {
        color: grey[25],
      },
    },
  },
  label: {
    ...typographyDefaultTheme.typography.root,
    ...typographyDefaultTheme.typography.common,
    marginLeft: '15px',
    // marginRight: '15px',
    padding: '0px',
  },
  deleteIcon: {
    border: 'none',
    height: '10.5px',
    width: '10.5px',
    color: blue[100],
    marginLeft: '15px',
    marginRight: '0px',
    '&:hover': {
      color: `${blue[100]}`,
    },

  },

};
const styles = (theme) => (
  {
    root: theme.root,
    labelSmall: theme.labelSmall,
    sizeSmall: theme.sizeSmall,
    deleteIconSmall: theme.deleteIconSmall,
    disabled: theme.disabled,
    label: theme.label,
    deleteIcon: theme.deleteIcon,

  }
);
const Tag = React.forwardRef((props, ref) => (<MuiChip ref={ref} clickable={false} deleteIcon={<CloseIcon />} {...props} />));

export default withStyles(styles, { name: 'AiSalesTag', defaultTheme })(Tag);
