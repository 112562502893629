import { Switch as MuiSwitch, withStyles } from '@material-ui/core';
import * as React from 'react';
import red from '../colors/red';
import green from '../colors/green';
import grey from '../colors/grey';

const defaultTheme = {
  root: {
    boxSizing: 'border-box',
    height: '20px',
    width: '40px',
    padding: 0,
    margin: '0.5em',
    // margin: theme.spacing(1),
    '& > span': {
      boxSizing: 'border-box',
      height: 'inherit',
    },
  },
  disabled: {
    '& $thumb': {
      backgroundColor: 'white',
      boxShadow: 'none',
    },
  },
  switchBase: {
    transition: 'background-color 0.2s, box-shadow 0.2s, color 0.2s, border-color 0.2s, transform 0.2s',
    padding: 1,
    '&$checked': {
      transform: 'translateX(19px)',
      color: 'white',
      '& + $track': {
        backgroundColor: green[100],
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      border: '6px solid #fff',
    },
    '&$disabled + $track': {
      backgroundColor: grey[25],
      opacity: 1,
    },
  },
  thumb: {
    boxSizing: 'border-box',
    width: 14,
    height: 14,
    marginBottom: '0px',
    marginLeft: '3px',
  },
  track: {
    boxSizing: 'border-box',
    borderRadius: 26 / 2,
    backgroundColor: red[100],
    opacity: 1,
    height: '100%',
  },
  checked: {},
  focusVisible: {},
};
const styles = (theme) => ({
  root: theme.root,
  disabled: theme.disabled,
  switchBase: theme.switchBase,
  thumb: theme.thumb,
  track: theme.track,
  checked: theme.checked,
  focusVisible: theme.focusVisible,

});
const Switch = React.forwardRef((props, ref) => {
  const { classes, ...others } = props;
  const { focusVisible, ...otherClasses } = classes;
  return (
    <MuiSwitch
      ref={ref}
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={otherClasses}
      {...others}
    />
  );
});
export default withStyles(styles, { name: 'AiSalesSwitch', defaultTheme })(Switch);
