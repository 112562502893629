import React from 'react';

export default ({ ...props }) => (
  <svg {...props} width="291" height="415" viewBox="0 0 291 415" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.2" d="M180.956 302C178.272 299.318 179.436 294.741 183.076 293.668L209 286.024L236.586 277.891C239.789 276.947 243 279.348 243 282.687V351.935C243 356.389 237.616 358.62 234.466 355.472L180.956 302Z" fill="white" />
    <path opacity="0.2" d="M228.266 204.036C226.699 199.988 230.68 196.003 234.729 197.566L252.323 204.355L274.699 212.99C277.666 214.134 278.824 217.72 277.088 220.384L255.759 253.115C253.534 256.529 248.378 255.99 246.907 252.19L228.266 204.036Z" fill="white" />
    <path opacity="0.2" d="M131.766 109.786C129.194 106.364 131.832 101.503 136.103 101.794L155.29 103.099L175.452 104.471C179.553 104.75 181.584 109.584 178.913 112.708L159.043 135.949C156.965 138.38 153.168 138.261 151.246 135.704L131.766 109.786Z" fill="white" />
    <path opacity="0.2" d="M57.312 15.5706C57.4186 11.2527 62.581 9.09228 65.7312 12.0473L71.0162 17.0048L79.93 25.3662C82.2529 27.5452 81.9737 31.3116 79.3548 33.1242L64.5747 43.354C61.2121 45.6813 56.6297 43.2074 56.7307 39.1193L57.312 15.5706Z" fill="white" />
    <path opacity="0.2" d="M295.597 356.461C299.147 355.226 302.739 358.203 302.186 361.92L298.257 388.306L297.912 425.483C297.881 428.87 294.562 431.247 291.346 430.185L198.783 399.64C194.244 398.143 194.192 391.74 198.706 390.17L295.597 356.461Z" fill="white" />
    <path opacity="0.2" d="M129.337 305.793C130.599 302.137 135.356 301.236 137.867 304.179L166.274 337.466L196.069 372.38C198.221 374.902 197.367 378.782 194.355 380.168L97.2011 424.871C93.2023 426.711 88.9489 422.859 90.3845 418.698L129.337 305.793Z" fill="white" />
    <defs>
      <clipPath id="clip0">
        <rect y="301" width="291" height="114" rx="5" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
