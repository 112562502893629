import { Menu, withStyles } from '@material-ui/core';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

const style = () => ({
  root: {

  },
  dropdownItem: {
  },
  dropdown: {
    width: '180px',
  },
  menu: {
    marginTop: '28px',

  },
  paper: {

    width: '300px',
    '&&&': {
      boxShadow: '0px 2px 20px rgba(0, 36, 70, 0.07)',
    },

  },
  icon: {
    cursor: 'pointer',
  },
  menuIcon: {
    color: 'black',
  },
  list: {
    '&& span:focus': {
      outline: 'none',
    },
    '&>div': {

      borderRadius: '5px',
      backgroundColor: 'white',
      '&:not(:first-of-type)': {
        marginTop: '10px',
      },
    },
  },
  removeIcon: {
    '&&&&': {
      color: 'red',
    },
  },
});

const CheckboxRadioSelect = withStyles(style)(({
  // eslint-disable-next-line no-unused-vars
  className, classes, index, menuIsOpen, offer, onClick, children, anchorElement, onClose, ...otherProps
}) => {
  const [ellipsisSquare, setEllipsisSquare] = useState();
  const ellipsisSquareRef = useCallback((node) => {
    setEllipsisSquare(node);
  }, []);

  return (
    <div className={className}>
      {React.cloneElement(anchorElement, { ref: ellipsisSquareRef, ...anchorElement.props })}
      <Menu
        disableAutoFocus
        onClose={onClose}
        onClick={onClick}
        className={classes.menu}
        classes={{ paper: classes.paper, list: classes.list }}
        anchorEl={ellipsisSquare}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
        open={menuIsOpen}
        /* eslint-disable-next-line react/jsx-props-no-spreading */
        {...otherProps}
      >
        {children}
      </Menu>
    </div>
  );
});

export default CheckboxRadioSelect;
