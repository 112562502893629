import { createElement as e, useEffect } from 'react';

// Create new React wrapper component
export default function Messenger() {
  window.messengerConfig = {
    OLD_REST_URL: '/backend/',
    REST_URL: '/backend/',
    USERNAME: JSON.parse(localStorage.getItem('user')).user.username,
    TOOLBAR_HEIGHT: '85px',
    WS_URL: `wss://${window.location.host}/backend/api/ws`,
    UID: JSON.parse(localStorage.getItem('user'))
      ? JSON.parse(localStorage.getItem('user')).user.user_id
      : '',
    PROJECT: JSON.parse(localStorage.getItem('user'))
      ? JSON.parse(localStorage.getItem('user'))?.current_project
      : '',
    OFFER_ID: window.location.pathname.includes('/app/offers/')
      ? window.location.pathname.replace('/app/offers/', '').split('/chat')[0]
      : undefined,
    CHANNEL_LIST: [
      {
        title: 'Telegram',
        value: 'telegram',
        icon: '/img/icons/telegram.png',
      },
      // {
      //   title: 'Viber',
      //   value: 'viber',
      //   icon: '/img/icons/viber.png',
      // },
      { title: 'VK', value: 'vk', icon: '/img/icons/vk.png' },
      // {
      //   title: 'Facebook',
      //   value: 'facebook',
      //   icon: '/img/icons/facebook.png',
      // },
      {
        title: 'Чат',
        value: 'authless_widget',
        icon: '/img/icons/authless.png',
      },
    ],
    PERMISSIONS: {
      messenger: '100',
      clients: '100',
      segments: '110',
    },
  };
  useEffect(() => function cleanup() {
    window.messengerConfig.unmount();
  }, []);
  // Helper function to create script elements
  const useScript = (url) => {
    useEffect(() => {
      const script = document.createElement('script');

      script.src = url;

      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }, [url]);
  };

  // Create vue-mesenger verndor script component
  useScript('/js/chunk-vendors.js?v=17');
  // Create vue-mesenger app script component
  useScript('/js/app.js?v=17');

  // Create vue-mesenger container component
  return e(
    'div',
    { style: { width: '100%', height: 'calc(100vh - 85px)' } },
    e('div', { id: 'messenger' }),
  );
}
