import React from 'react';
import { withStyles, Modal as MuiModal } from '@material-ui/core';
import clsx from 'clsx';
import { CloseIcon, Typography } from '../index';
import { greyColor } from '../colors/index';

const style = () => ({
  modal: {
    overflow: 'scroll',
  },
  root: {
    '&&&&&:focus': {
      outline: 'none',
    },
    marginTop: '30px',
    width: '800px',
    marginLeft: 'auto',
    marginRight: 'auto',
    // padding: '30px',
    boxSizing: 'border-box',
    position: 'relative',
    boxShadow: '0px 2px 20px rgba(0, 36, 70, 0.07)',
    borderRadius: '5px',
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    height: '13.13px',
    width: '13.13px',
    top: '33.75px',
    right: '33.12px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px',

  },
  header_withGreyLine: {
    borderBottom: `1px solid ${greyColor[10]}`,
  },
  contents: {
    padding: '0 30px 30px 30px',
  },
  bottomPart: {
    padding: '30px',
  },
  bottomPart_withGreyLine: {
    borderTop: `1px solid ${greyColor[10]}`,
  },
});

const Modal = withStyles(style)(({
  classes,
  open,
  onClose,
  children,
  title,
  headerWithGreyLine,
  bottomPart,
}) => (
  <MuiModal
    className={classes.modal}
    open={open}
    onClose={onClose}
  >
    <div className={classes.root} style={{ backgroundColor: 'white' }}>
      <div className={clsx(classes.header, { [classes.header_withGreyLine]: headerWithGreyLine })}>
        <CloseIcon className={classes.closeIcon} onClick={() => { onClose(); }} />
        <Typography variant="title">
          {title}
        </Typography>
      </div>
      <div className={classes.contents}>
        {children}
      </div>
      {bottomPart && (
      <div className={clsx(classes.bottomPart, classes.bottomPart_withGreyLine)}>
        {bottomPart}
      </div>
      )}
    </div>
  </MuiModal>
));

export default Modal;
