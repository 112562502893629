import React from 'react';
import { TableBody as MuiTableBody, withStyles } from '@material-ui/core';
import { blueColor, greyColor } from '../colors';

const style = (theme) => ({
  root: {
    // '&&&& tr:hover td, &&&& tr:hover th': {
    //     backgroundColor: blueColor[20],
    //     transition: "background 0.2s"
    // },
    '& tr:nth-child(2n) td,& tr:nth-child(2n) th': {
      backgroundColor: greyColor.background,
    },
  },
});
const TableBody = React.forwardRef((props, ref) => <MuiTableBody {...props} ref={ref} />);

export default withStyles(style)(TableBody);
