import { Tabs as MuiTabs, withStyles } from '@material-ui/core';
import * as React from 'react';
import clsx from 'clsx';
import blue from '../colors/blue';
import { parseChildren } from '../utils';

const styles = (theme) => ({
  root: {
    minHeight: 'auto',
  },
  flexContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  indicator: {

  },
  underlined: {
    '& $indicator': {
      backgroundColor: blue[100],
    },
  },
  common: {
    height: '40px',
    '& $indicator': {
      backgroundColor: 'transparent',
    },
  },
  tab: {
    marginRight: '15px',
  },
});

const Tabs = React.forwardRef((props, ref) => {
  const {
    children, styleVariant = 'underlined', classes, className, ...others
  } = props;
  const {
    underlined, common, tab, ...otherClasses
  } = classes;
  return (
    <MuiTabs ref={ref} className={clsx(classes[styleVariant], className)} classes={otherClasses} {...others}>
      {parseChildren(children).map((child, index) => React.cloneElement(child, {
        styleVariant, key: index, className: clsx(child.props?.className, classes.tab), ...child.props,
      }))}

    </MuiTabs>
  );
});

export default withStyles(styles, { name: 'AiSalesTabs' })(Tabs);
