import { FormLabel, withStyles } from '@material-ui/core';
import { typographyDefaultTheme } from '../Typography';

const defaultTheme = {
  root: {
    transition: 'background-color 0.2s, box-shadow 0.2s, color 0.2s, border-color 0.2s',
    ...typographyDefaultTheme.typography.root,
  },
  disabled: typographyDefaultTheme.typography.disabled,
};

const styles = (theme) => (
  {
    root: defaultTheme.root,
    disabled: defaultTheme.disabled,
  }
);

export default withStyles(styles)(FormLabel);
