import React from 'react';

export default ({ ...props }) => (
  <svg
    {...props}
    width="238"
    height="358"
    viewBox="0 0 238 358"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M85.0771 291.739C81.5559 290.275 80.9033 285.567 83.8937 283.201L107.859 264.238L133.264 244.136C135.878 242.068 139.746 243.088 141 246.177L170.099 317.857C171.769 321.971 167.647 326.059 163.547 324.355L85.0771 291.739Z"
      fill="white"
    />
    <path
      d="M143.21 200.188C141.33 196.276 144.984 191.991 149.144 193.23L167.217 198.613L190.204 205.459C193.251 206.366 194.689 209.85 193.168 212.642L174.481 246.95C172.532 250.529 167.35 250.398 165.584 246.725L143.21 200.188Z"
      fill="white"
    />
    <path
      d="M214.875 106.585C218.349 103.625 223.646 106.485 223.078 111.013L219.91 136.272L215.887 168.353C215.498 171.453 212.393 173.441 209.415 172.497L165.596 158.606C161.853 157.42 160.876 152.58 163.865 150.034L214.875 106.585Z"
      fill="white"
    />
    <path
      d="M74.9337 151.545C70.5957 151.682 68.1543 146.607 70.9698 143.304L83.2031 128.952L98.7618 110.698C100.824 108.279 104.592 108.373 106.53 110.893L130.348 141.86C132.833 145.09 130.617 149.776 126.544 149.906L74.9337 151.545Z"
      fill="white"
    />
    <path
      d="M114.956 63.0003C112.272 60.318 113.436 55.7407 117.076 54.6676L143 47.0244L170.586 38.8911C173.789 37.9467 177 40.3476 177 43.687V112.935C177 117.389 171.616 119.62 168.466 116.472L114.956 63.0003Z"
      fill="white"
    />
    <path
      d="M73.8034 4.38217C76.729 2.30653 80.8239 3.89577 81.5833 7.40157L86.025 27.907L91.6343 53.7162C92.3269 56.9029 89.8326 59.8891 86.5734 59.775L14.7798 57.2616C9.99934 57.0943 8.16027 50.9546 12.0616 48.1868L73.8034 4.38217Z"
      fill="white"
    />
  </svg>
);
