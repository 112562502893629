import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import Typography from '../Typography';

const style = (theme) => ({
  root: {
    backgroundColor: 'white',
  },
});
function TabPanel(props) {
  const {
    children, classes, value, index, className, ...other
  } = props;
  if (index ? value === index : true) {
    return (
      <div className={clsx(classes.root, className)}>
        {' '}
        {children}
        {' '}
      </div>
    );
  }
  return (<div className={classes.root} />);
}

export default withStyles(style)(TabPanel);
