import clsx from 'clsx';
import React, { useEffect, useRef, useState } from 'react';
import { withStyles } from '@material-ui/styles';
import { Tooltip as MuiTooltip } from '@material-ui/core';
import { CrossIcon, ExclamationMarkIcon } from '../icons';
import { blueColor } from '../colors';
import { typographyDefaultTheme } from '../Typography';

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, onClickOutside) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

/**
 * Component that alerts if you click outside of it
 */
function OutsideAlerter(props) {
  const { onClickOutside } = props;
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, () => {
        onClickOutside?.();
  });

  return <div ref={wrapperRef}>{props.children}</div>;
}

const tooltipStyle = (theme) => ({
  exclamationMark: {
    width: '24px',
    height: '24px',
    marginBottom: '15px',
    color: blueColor[100],
  },
  crossIcon: {
    position: 'absolute',
    right: '20px',
    cursor: 'pointer',
  },
  content: {
    zIndex: '10000',
    padding: '20px',
    width: '300px',
    background: 'white',
    boxShadow: '0px 2px 20px rgba(0, 36, 70, 0.07)',
    borderRadius: '5px',
  },
  smallContent: {
    width: 'fit-content',
    padding: '5px 15px',
  },
  popper: {
    pointerEvents: 'auto',
  },
  tooltip: {
    maxWidth: 'fit-content',
    padding: '0',
    backgroundColor: 'transparent',
    ...typographyDefaultTheme.typography.root,
    ...typographyDefaultTheme.typography.normal,
    ...typographyDefaultTheme.typography.common,
  },
  root: {},
});

const Tooltip = React.forwardRef((props, ref) => {
  const {
    text, children,
    classes,
    onClose,
    onClickCloseIcon,
    opened,
    variant, ...otherProps
  } = props;

  const {
    content: contentClass,
    crossIcon,
    exclamationMark,
    root,
    smallContent,
    ...otherClasses
  } = classes;

  const [open, setOpen] = useState(opened || false);

  useEffect(() => {
    setOpen(opened);
  }, [opened]);

  const content = (
    <div className={clsx(classes.content, { [classes.smallContent]: variant === 'small' })}>
      {variant !== 'small' && (
      <div>
        <CrossIcon
          className={classes.crossIcon}
          onClick={() => {
            if (opened === undefined) {
              setOpen(false);
            }

                onClose?.();
                onClickCloseIcon?.();
          }}
        />
        <ExclamationMarkIcon className={classes.exclamationMark} />
      </div>
      )}
      <div>
        {text}
      </div>
    </div>
  );
  return (
    <OutsideAlerter onClickOutside={() => {
      if (opened === undefined) {
        setOpen(false);
      }
        onClose?.();
    }}
    >
      <MuiTooltip
        classes={otherClasses}
        open={variant !== 'small' ? open : undefined}
        ref={ref}
        title={content}
        placement={variant === 'small' ? 'bottom' : 'bottom-start'}
        {...otherProps}
      >
        {React.cloneElement(children, {
          ...children.props,
          onClick: () => {
            if (variant !== 'small') {
              if (opened === undefined) {
                setOpen(true);
              }
            }
                children?.props?.onClick?.();
          },
        })}
      </MuiTooltip>
    </OutsideAlerter>
  );
});
export default withStyles(tooltipStyle)(Tooltip);
