import React from 'react';

export default (props) => (
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M5.64269 16C5.21883 16 4.90093 15.7 4.90093 15.3V0.7C4.79496 0.3 5.21883 0 5.64269 0C6.06656 0 6.38446 0.3 6.38446 0.7V15.2C6.38446 15.7 6.06656 16 5.64269 16Z" fill="currentColor" />
      <path d="M10.35 16C9.97501 16 9.60001 15.7 9.60001 15.2V4.99995C9.60001 4.59995 9.88126 4.19995 10.35 4.19995C10.8188 4.19995 11.1 4.49995 11.1 4.99995V15.3C11.1 15.7 10.725 16 10.35 16Z" fill="currentColor" />
      <path d="M15.3 15.9999C14.9 15.9999 14.5 15.6999 14.5 15.1999V8.6999C14.5 8.2999 14.8 7.8999 15.3 7.8999C15.8 7.8999 16 8.2999 16 8.6999V15.1999C16 15.6999 15.7 15.9999 15.3 15.9999Z" fill="currentColor" />
      <path d="M0.75 16C0.28125 16 0 15.6625 0 15.2125V7.7875C0 7.3375 0.28125 7 0.75 7C1.21875 7 1.5 7.3375 1.5 7.9V15.2125C1.40625 15.6625 1.125 16 0.75 16Z" fill="currentColor" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
