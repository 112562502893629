import React from 'react';

const DialogueIcon = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M1.75 1.75C1.61193 1.75 1.5 1.86193 1.5 2V10.3987L3.26986 8.92383C3.40465 8.81151 3.57455 8.75 3.75 8.75H11.25C11.3881 8.75 11.5 8.63807 11.5 8.5V2C11.5 1.86193 11.3881 1.75 11.25 1.75H1.75ZM0 2C0 1.0335 0.783502 0.25 1.75 0.25H11.25C12.2165 0.25 13 1.0335 13 2V8.5C13 9.4665 12.2165 10.25 11.25 10.25H4.02154L1.23014 12.5762C1.00658 12.7625 0.695422 12.8026 0.431883 12.6792C0.168345 12.5558 0 12.291 0 12V2Z" fill="currentColor" />
    <path d="M15 7V15L13 12.5H6.5C5.94772 12.5 5.5 12.0523 5.5 11.5V7C5.5 6.44772 5.94772 6 6.5 6H14C14.5523 6 15 6.44772 15 7Z" fill="#E6F3FF" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5 7C5 6.0335 5.7835 5.25 6.75 5.25H14.25C15.2165 5.25 16 6.0335 16 7V15C16 15.3185 15.7988 15.6023 15.4983 15.7077C15.1977 15.8132 14.8633 15.7172 14.6643 15.4685L12.8895 13.25H6.75C5.7835 13.25 5 12.4665 5 11.5V7ZM6.75 6.75C6.61193 6.75 6.5 6.86193 6.5 7V11.5C6.5 11.6381 6.61193 11.75 6.75 11.75H13.25C13.4778 11.75 13.6933 11.8536 13.8357 12.0315L14.5 12.8619V7C14.5 6.86193 14.3881 6.75 14.25 6.75H6.75Z" fill="currentColor" />
  </svg>
);

export default DialogueIcon;
