import React from 'react';

export default (props) => (
  <svg {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 -0.000213623C4.47711 -0.000213623 0 4.4769 0 9.99979C0 15.5227 4.47711 19.9998 10 19.9998C15.5229 19.9998 20 15.5227 20 9.99979C20 4.4769 15.5229 -0.000213623 10 -0.000213623Z"
      fill="#369EFE"
    />
    <path
      d="M14.8661 5.75213L13.08 14.7575C13.08 14.7575 12.8302 15.382 12.1432 15.0823L8.02151 11.9223L6.52276 11.1979L3.9998 10.3485C3.9998 10.3485 3.61261 10.2112 3.57511 9.91142C3.53761 9.61166 4.0123 9.44931 4.0123 9.44931L14.0417 5.51494C14.0417 5.51494 14.866 5.15275 14.866 5.75228"
      fill="white"
    />
    <path
      d="M7.70498 14.6563C7.70498 14.6563 7.58467 14.645 7.43467 14.1703C7.2849 13.6957 6.52295 11.1978 6.52295 11.1978L12.5806 7.35088C12.5806 7.35088 12.9304 7.13853 12.9179 7.35088C12.9179 7.35088 12.9803 7.38837 12.7929 7.56322C12.6056 7.73814 8.03428 11.8473 8.03428 11.8473"
      fill="#D2E5F1"
    />
    <path
      d="M9.6017 13.1338L7.97146 14.6202C7.97146 14.6202 7.84396 14.7169 7.70459 14.6563L8.01678 11.8953"
      fill="#B5CFE4"
    />
    <defs>
      <clipPath id="clip0">
        <rect width="20" height="20" fill="white" transform="translate(0 -0.000213623)" />
      </clipPath>
    </defs>
  </svg>
);
