import * as React from 'react';
import { cloneElement, useEffect } from 'react';
import { MenuItem as MuiMenuItem, withStyles } from '@material-ui/core';
import blue from '../colors/blue';
import { TickIcon } from '../icons';
import { blueColor } from '../colors';

const styles = (theme) => ({
  root: {
    transition: 'background-color 0.2s, box-shadow 0.2s, color 0.2s, border-color 0.2s',
    '&&&': {
      backgroundColor: 'white',
    },
    height: '45px',
    padding: '0px',
    paddingLeft: '15px',
    'li:hover&&&&': {
      backgroundColor: blueColor[15],
    },
    '&:hover $child': {
      color: blue[90],
    },
  },
  tickIconWrapper: {
    paddingRight: '15px',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    color: blue[100],
    boxSizing: 'content-box',
    width: '14px',
  },
  tickIcon: {
    height: '10px',
  },

  child: {
    height: '100%',
  },
});

const SelectItem = React.forwardRef((props, ref) => {
  const {
    children, selectedCustom, classes, multiple, ...others
  } = props;
  const {
    tickIcon,
    opened,
    subItemIcon,
    mainItemIcon,
    iconWrapper,
    selectedIndicator,
    mainItemWrapper,
    mainItem,
    subItem,
    child,
    tickIconWrapper,
    ...otherClasses
  } = classes;
  return (
    <MuiMenuItem
      disableRipple
      ref={ref}
      classes={otherClasses}
      {...others}
    >
      {children && (Array.isArray(children) ? children.map((child) => cloneElement(child, {
        selected: selectedCustom,
        className: classes.child,
        ...children.props,
      }))
        : cloneElement(children, { selected: selectedCustom, className: classes.child, ...children.props }))}
      {(selectedCustom && multiple)
        && <div className={classes.tickIconWrapper}><TickIcon className={classes.tickIcon} /></div>}
    </MuiMenuItem>
  );
});

SelectItem.muiName = 'SelectItem';

export default withStyles(styles, { name: 'AIsalesSelectItem' })(SelectItem);
