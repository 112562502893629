import React from 'react';
// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
  <svg {...props} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.05025 6C4.84925 6 4.64824 5.90042 4.44724 5.80083L0.226131 1.3195C-0.0753769 1.02075 -0.0753769 0.522822 0.226131 0.224066C0.527638 -0.0746888 0.929648 -0.0746888 1.23116 0.224066L4.94975 4.20747L8.76884 0.224066C9.07035 -0.0746888 9.47236 -0.0746888 9.77387 0.224066C10.0754 0.522822 10.0754 1.02075 9.77387 1.3195L5.55276 5.80083C5.35176 5.90042 5.25126 6 5.05025 6Z"
      fill="currentColor"
    />
  </svg>
);
