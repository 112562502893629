import React from 'react';

const StarIconFilled = (props) => (
  <svg {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9 1.12978L11.3742 5.94048C11.5199 6.23562 11.8015 6.44019 12.1272 6.48752L17.4361 7.25895L13.5945 11.0036C13.3588 11.2333 13.2513 11.5643 13.3069 11.8887L14.2138 17.1762L9.46534 14.6798C9.17402 14.5266 8.82598 14.5266 8.53466 14.6798L3.78621 17.1762L4.69309 11.8887C4.74872 11.5643 4.64118 11.2333 4.40549 11.0036L0.563916 7.25895L5.87284 6.48752C6.19855 6.44019 6.48011 6.23562 6.62578 5.94048L9 1.12978Z"
      fill="white"
      stroke="white"
    />
  </svg>
);
export default StarIconFilled;
