import { Checkbox as MuiCheckbox, withStyles } from '@material-ui/core';
import * as React from 'react';
import classNames from 'classnames';
import { TickIcon } from '../icons';
import grey from '../colors/grey';
import blue from '../colors/blue';

const defaultTheme = {
  root: {
    transition: 'background-color 0.2s, box-shadow 0.2s, color 0.2s, border-color 0.2s',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    transition: 'background-color 0.2s, box-shadow 0.2s, color 0.2s, border-color 0.2s',
    boxSizing: 'border-box',
    height: '20px',
    width: '20px',
    border: '1px solid',
    borderRadius: 3,
    boxShadow: 'none',
    backgroundColor: 'white',
    borderColor: grey[25],
    'input:hover ~ &': {
      borderColor: blue[100],
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: grey[10],
      borderColor: grey[25],
    },
  },
  checkedIcon: {
    color: 'white',
    backgroundColor: blue[100],
    'svg&': {
      display: 'block',
      padding: '1px',
    },
    'input:hover ~ &': {
      borderColor: grey[25],
    },
    'input:disabled ~ &': {
      color: grey[25],
    },
  },

};

const styles = (theme) => (
  {
    root: theme.root,
    icon: theme.icon,
    checkedIcon: theme.checkedIcon,

  });

// const StyledCheckbox = withStyles(styles)(MuiCheckbox)
const Checkbox = React.forwardRef((props, ref) => {
  const {
    classes,
    disabled,
    ...other
  } = props;
  const { icon, checkedIcon, ...otherClasses } = classes;
  const checkedIconComponent = <TickIcon className={classNames(icon, checkedIcon)} />;
  return (
    <MuiCheckbox
      disabled={disabled}
      disableRipple
      color="default"
      ref={ref}
      checkedIcon={checkedIconComponent}
      icon={<span className={classNames(icon)} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      classes={otherClasses}
      {...other}
    />
  );
});

export default withStyles(styles, { name: 'MuiCheckbox', defaultTheme })(Checkbox);
