import React from 'react';

export default (props) => (
  <svg {...props} width="288" height="185" viewBox="0 0 288 185" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.2" d="M174.694 74.86C171.067 75.9499 167.592 72.7693 168.358 69.0606L172.19 50.4995L176.364 30.2826C177.04 27.0097 180.675 25.3082 183.621 26.8856L227.133 50.1809C231.064 52.2856 230.482 58.094 226.212 59.3774L174.694 74.86Z" fill="white" />
    <path opacity="0.2" d="M110.868 86.7668C106.56 86.2377 104.926 80.8478 108.214 78.0155L117.291 70.1981L130.253 59.0354C132.662 56.9606 136.37 57.6318 137.9 60.4195L150.654 83.6726C152.614 87.2456 149.706 91.5367 145.661 91.0399L110.868 86.7668Z" fill="white" />
    <path opacity="0.2" d="M67.7508 54.8014C63.52 55.4537 60.4792 50.8342 62.7512 47.2061L69.3073 36.7367L76.382 25.4394C78.5638 21.9553 83.7841 22.4464 85.2779 26.2763L92.5126 44.8257C93.6751 47.8062 91.7782 51.0966 88.6163 51.5841L67.7508 54.8014Z" fill="white" />
    <path opacity="0.2" d="M20.3904 74.6165C16.634 72.4845 17.166 66.9136 21.258 65.5311L24.1442 64.556L31.7442 61.9883C34.7616 60.9688 37.9503 62.9928 38.3122 66.1571L39.4735 76.3106C39.9382 80.3736 35.5944 83.2457 32.0378 81.2272L20.3904 74.6165Z" fill="white" />
    <path opacity="0.2" d="M244.527 19.4049C244.144 15.666 247.869 12.8571 251.358 14.2532L268.727 21.2019L295.073 27.7049C298.361 28.5166 299.908 32.294 298.133 35.1789L261.293 95.0677C258.789 99.1388 252.548 97.7122 252.061 92.9574L244.527 19.4049Z" fill="white" />
    <path opacity="0.2" d="M172.227 118.326C169.428 115.657 170.588 110.956 174.309 109.898L204.879 101.199L237.506 91.9149C240.695 91.0075 243.868 93.3984 243.875 96.7139L244.033 175.088C244.041 179.49 238.769 181.754 235.583 178.717L172.227 118.326Z" fill="white" />
  </svg>
);
