import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { IconButton, withStyles } from '@material-ui/core';
import { MailIcon, SupportIcon } from '../icons';
import {
  Avatar, Select, SelectItem, TextWithIcon,
} from '../index';
import blue from '../colors/blue';
import { typographyDefaultTheme } from '../Typography';

const styles = (theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  appBar: {
    height: '80px',
    backgroundColor: 'white',
    boxShadow: '0px 2px 20px rgba(0, 36, 70, 0.07)',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  content: {
    position: 'relative',
  },
  leftPart: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  rightPart: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  rightElement: {
    height: '100%',
    borderRight: '1px solid #EAEBEB;',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '30px',
    paddingLeft: '30px',
  },
  childrenWrapper: {
    flex: '1',
    position: 'relative',
  },
  children: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },

});

function Header(props) {
  const {
    children, classes, left, right,
  } = props;
  return (
    <div className={classes.root}>
      <AppBar position="sticky" className={classes.appBar}>
        <div className={classes.leftPart}>
          {left}
        </div>
        <div className={classes.rightPart}>
          {right.map((rightElem) => <div className={classes.rightElement}>{rightElem}</div>)}
        </div>
      </AppBar>
      <div className={classes.childrenWrapper}>
        <div className={classes.children}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default withStyles(styles)(Header);
