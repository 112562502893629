import * as React from 'react';
import { useEffect, useState } from 'react';
import { Select as MuiSelect, withStyles } from '@material-ui/core';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import clsx from 'clsx';
import { blueColor, greyColor } from '../colors';
import {
  Chip, DropdownIcon, Input, DragAndDropIcon,
} from '../index';

const styles = () => ({
  selectMenu: {

  },
  root: {
    // width: 'fit-content',

    transition: 'background-color 0.2s, box-shadow 0.2s, color 0.2s, border-color 0.2s',
    textOverflow: 'unset',
    '&$root': {
      boxSizing: 'border-box',
      '&:focus': {
        backgroundColor: 'transparent',
      },
      position: 'relative',
      left: 0,
      paddingRight: '45px',
      paddingLeft: '15px',
    },
    '& .MuiPaper-root': {
      marginTop: '15px',
    },
  },
  icon: {
    display: 'none',
  },
  inputRoot: {
    padding: '0px',
    '&&&&&& svg': {
      color: 'inherit',
    },
    width: 'fit-content',
  },
  adornment: {
    color: 'inherit',
  },
  inputIcon: {
    position: 'absolute',
    right: '0',
    boxSizing: 'content-box',
    paddingRight: '15px',
    height: '100%',
    top: '0',
    cursor: 'pointer',

  },
  tag: {
    marginLeft: '5px',
  },
  paper: {
    marginTop: '15px',
    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
  },
  list: {
    '& div:focus': {
      outline: 'none',
    },
    padding: '0px',
  },
  disabled: {

  },
  inputOpen: {},
  chipLabel: {
    display: 'flex',
    alignItems: 'center',
    '&&&&&&&': {
      '&>svg': {
        marginRight: '5px',
        color: blueColor[30],
      },
      '&&:hover>svg': {
        marginRight: '5px',
        color: blueColor[20],
      },

    },

  },
  currentDraggable: {
    backgroundColor: blueColor[30],
    '&&&&&&&&&& $chipLabel': {
      '&>svg': {
        color: blueColor[100],
      },
    },

  },
  chipLabelDisabled: {
    '&&&&&&&&>svg': {
      color: greyColor[25],
    },

  },

});

// const defaultTickIcon = <TickIcon/>
const Select = React.forwardRef((props, ref) => {
  const {
    children,
    onChange,
    multiple,
    open: propsOpen,
    value,
    draggable,
    disabledValues,
    disabled,
    label,
    placeholder,
    renderTagsValue,
  } = props;

  const { classes, maxElementsNum = children?.length, ...otherProps } = props;
  const {
    inputOpen, list, paper, tag, inputIcon, adornment, inputRoot, ...otherClasses
  } = classes;
  const handleDelete = (index) => {
    onChange({ target: { value: value.filter((element, elementIndex) => elementIndex !== index) } });
    setOpen(false);
  };
  const isChipDisabled = (value, disabledValues) => !!(disabledValues && disabledValues.includes(value));
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const removeChildrenFromSameGroup = (selectedChild, selectedChildren) => [...selectedChildren.filter((child) => child.props.value === selectedChild.props.value || !child.props.group || (child.props.group !== selectedChild.props.group))];
  const getSelectedChildren = (values) => {
    const stringifiedValues = values.map((value) => JSON.stringify(value));
    return children.filter((child) => (stringifiedValues.includes(JSON.stringify(child.props.value)) && child.type !== 'div'));
  };
  const getValuesFromChildren = (someChildren) => someChildren.map((child) => child.props.value);
  const [open, setOpen] = useState(propsOpen);
  const [currentDraggableIndex, setCurrentDraggableIndex] = useState(null);
  useEffect(() => {
  }, []);
  useEffect(() => {
    setOpen(propsOpen);
  }, [propsOpen]);
  useEffect(() => {
    if (open === false) {
      if (props.onClose) {
        props.onClose();
      }
    }
  }, [open]);

  function onDragEnd(result) {
    setCurrentDraggableIndex(null);
    if (!result.destination) {
      return;
    }

    if (result.destination.index === result.source.index) {
      return;
    }

    const newValues = reorder(
      value,
      result.source.index,
      result.destination.index,
    );
    onChange({ target: { value: newValues } });
  }

  function isSelected(optionValue) {
    const isSelected = value.map((valueElem) => JSON.stringify(valueElem)).includes(JSON.stringify(optionValue));
    return isSelected;
  }

  function onDragStart(result) {
    setCurrentDraggableIndex(result.source.index);
  }

  return (
    <MuiSelect
      ref={ref}
      open={Boolean(open)}
      onClick={() => {
        if (!disabled) {
          setOpen(!open);
        }
      }}
      MenuProps={{
        classes: { paper: classes.paper, list: classes.list },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
      }}
      input={(
        <Input
          placeholder={placeholder}
          label={label}
          className={{ [classes.inputOpen]: open }}
          classes={{ root: classes.inputRoot, adornment: classes.adornment }}
          icon={<div><DropdownIcon className={classes.inputIcon} /></div>}
        />
                      )}
      renderValue={(selected) => {
        if (multiple) {
          const partOfSelectedOptions = selected.slice(0, maxElementsNum).map((value, index) => (
            <Draggable
              draggableId={JSON.stringify(value)}
              index={index}
              key={JSON.stringify(value)}
              isDragDisabled={!draggable}
            >
              {(provided) => (

                <Chip
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  disabled={isChipDisabled(value, disabledValues)}
                  {...provided.dragHandleProps}
                  className={clsx(classes.tag,
                    { [classes.currentDraggable]: index === currentDraggableIndex })}
                  size="small"
                  onClick={(event) => {
                    event.nativeEvent.stopImmediatePropagation();
                    event.stopPropagation();
                  }}
                  onDelete={() => handleDelete(index)}
                  label={(
                    <div className={clsx(classes.chipLabel,
                      { [classes.chipLabelDisabled]: isChipDisabled(value, disabledValues) })}
                    >
                      {draggable && <DragAndDropIcon />}
                      {' '}
                      {renderTagsValue ? renderTagsValue(value) : value}
                    </div>
)}
                />
              )}
            </Draggable>

          ));
          const wrapped = (
            <DragDropContext style={{ width: '100%' }} onDragEnd={onDragEnd} onDragStart={onDragStart}>
              <Droppable style={{ width: '100%' }} droppableId="droppable" direction="horizontal">
                {(provided) => (
                  <span
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {partOfSelectedOptions}
                  </span>
                )}
              </Droppable>
            </DragDropContext>
          );

          const numberOfLeft = selected.length - maxElementsNum;

          const finalResult = numberOfLeft > 0 ? [wrapped,
            <Chip
              className={classes.tag}
              size="small"
              label={`+${numberOfLeft}`}
            />] : wrapped;
          return finalResult;
        }
        return selected;
      }}

      {...otherProps}
      onChange={(event, child) => {
        if (multiple) {
          const newValues = getValuesFromChildren(
            removeChildrenFromSameGroup(child,
              getSelectedChildren(event.target.value)),
          );

          const newEvent = { ...event, target: { ...event.target, value: newValues } };
          onChange?.(newEvent, child);
        } else {
          onChange?.(event, child);
        }
      }}
      classes={otherClasses}
    >
      {children
      && children.map((child, index) => {
        if (child.type !== 'div') {
          return React.cloneElement(child,
            {
              multiple,
              ...child.props,
              selectedCustom: multiple
                ? isSelected(child.props.value)
                : JSON.stringify(child.props.value) === JSON.stringify(value),
            });
        }
        return React.cloneElement(child, { onClick: () => {} });
      })}
    </MuiSelect>
  );
});

export default withStyles(styles, { name: 'AIsalesSelect' })(Select);
