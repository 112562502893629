import { TablePagination as MuiTablePagination, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import clsx from 'clsx';
import {
  Pagination, Select, SelectItem, TableCell, TextWithIcon,
} from '../index';
import { greyColor } from '../colors/index';

const styles = () => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'white',
    padding: '30px 0px',
    borderTop: '1px solid #CACACA',

  },
  centralizedVertically: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    width: 'fit-content',
    height: '30px',
    borderColor: greyColor[50],
    color: greyColor[25],
  },
  selectInputIcon: {
    '&&&&&&&&': {
      color: greyColor[50],
    },

  },
});

const TablePagination = React.forwardRef((props, ref) => {
  const {
    rowPerPageOptions = [10, 15, 30],
    count,
    rowPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    classes, ...otherProps
  } = props;
  return (
    <div className={clsx(classes.centralizedVertically, classes.root)}>

      <Select
        className={classes.select}
        classes={{ inputIcon: classes.selectInputIcon }}
        value={rowPerPage}
        onChange={(event) => { onChangeRowsPerPage(event); }}
      >
        {rowPerPageOptions.map((rowPerPageOption) => (
          <SelectItem value={rowPerPageOption}>
            <TextWithIcon label={rowPerPageOption} />
          </SelectItem>
        ))}
      </Select>
      <Pagination count={Math.ceil(count / rowPerPage)} page={page} onChange={(event, page) => { onChangePage(event, page); }} />
    </div>
  );
});

export default withStyles(styles)(TablePagination);
