import * as React from 'react';
import { Radio as MUIRadio, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import blue from '../colors/blue';
import grey from '../colors/grey';

const defaultTheme = {
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
    transition: 'background-color 0.2s, box-shadow 0.2s, color 0.2s, border-color 0.2s',
  },
  icon: {
    transition: 'background-color 0.2s, box-shadow 0.2s, color 0.2s, border-color 0.2s',
    borderRadius: '50%',
    boxSizing: 'border-box',
    width: 20,
    height: 20,
    border: '1px solid',
    borderColor: grey[25],
    backgroundColor: '#f5f8fa',
    'input:hover:not(:disabled) ~ &': {
      borderColor: blue[100],
    },
    'input:disabled ~ &': {
      background: grey[10],
      borderColor: grey[15],
    },
  },
  checkedIcon: {
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > span': {
      display: 'block',
      backgroundColor: blue[100],
      borderRadius: '50%',
      content: '""',
      width: '12px',
      height: '12px',
      'input:disabled ~ &': {
        background: grey[25],
      },
    },
    'input:hover ~ &': {
    },
  },
};
const styles = (theme) => ({
  root: theme.root,
  icon: theme.icon,
  checkedIcon: theme.checkedIcon,
});
const Radio = React.forwardRef((props, ref) => {
  const { classes, ...others } = props;
  const { icon, checkedIcon, ...otherClasses } = classes;
  return (
    <MUIRadio
      ref={ref}
      disableRipple
      color="default"
      checkedIcon={<span className={classNames(classes.icon, classes.checkedIcon)}><span /></span>}
      icon={<span className={classes.icon} />}
      classes={otherClasses}
      {...others}
    />
  );
});
export default withStyles(styles, { name: 'MuiRadio', defaultTheme })(Radio);
