import React from 'react';
import { TableRow as MuiTableRow, withStyles } from '@material-ui/core';

const style = (theme) => ({
});
const TableRow = React.forwardRef((props, ref) => {
  const { children, ...otherProps } = props;
  return <MuiTableRow ref={ref} {...otherProps}>{children}</MuiTableRow>;
});

export default withStyles(style)(TableRow);
