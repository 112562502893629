import React from 'react';

const FilterIcon = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.75 3C0.75 2.58579 1.08579 2.25 1.5 2.25L7 2.25C7.41421 2.25 7.75 2.58579 7.75 3C7.75 3.41421 7.41421 3.75 7 3.75L1.5 3.75C1.08579 3.75 0.75 3.41421 0.75 3Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0.75 8C0.75 7.58579 1.08579 7.25 1.5 7.25L7 7.25C7.41421 7.25 7.75 7.58579 7.75 8C7.75 8.41421 7.41421 8.75 7 8.75L1.5 8.75C1.08579 8.75 0.75 8.41421 0.75 8Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.25 13C8.25 12.5858 8.58579 12.25 9 12.25L14.5 12.25C14.9142 12.25 15.25 12.5858 15.25 13C15.25 13.4142 14.9142 13.75 14.5 13.75L9 13.75C8.58579 13.75 8.25 13.4142 8.25 13Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.25 3C9.25 2.58579 9.58579 2.25 10 2.25L14.5 2.25C14.9142 2.25 15.25 2.58579 15.25 3C15.25 3.41421 14.9142 3.75 14.5 3.75L10 3.75C9.58579 3.75 9.25 3.41421 9.25 3Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0.75 13C0.75 12.5858 1.08579 12.25 1.5 12.25H6C6.41421 12.25 6.75 12.5858 6.75 13C6.75 13.4142 6.41421 13.75 6 13.75H1.5C1.08579 13.75 0.75 13.4142 0.75 13Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.25 8C9.25 7.58579 9.58579 7.25 10 7.25L14.5 7.25C14.9142 7.25 15.25 7.58579 15.25 8C15.25 8.41421 14.9142 8.75 14.5 8.75H10C9.58579 8.75 9.25 8.41421 9.25 8Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7 0.25C7.41421 0.25 7.75 0.585786 7.75 1L7.75 5C7.75 5.41421 7.41421 5.75 7 5.75C6.58579 5.75 6.25 5.41421 6.25 5L6.25 1C6.25 0.585786 6.58579 0.25 7 0.25Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10 5.25C10.4142 5.25 10.75 5.58579 10.75 6V10C10.75 10.4142 10.4142 10.75 10 10.75C9.58579 10.75 9.25 10.4142 9.25 10V6C9.25 5.58579 9.58579 5.25 10 5.25Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6 10.25C6.41421 10.25 6.75 10.5858 6.75 11L6.75 15C6.75 15.4142 6.41421 15.75 6 15.75C5.58579 15.75 5.25 15.4142 5.25 15L5.25 11C5.25 10.5858 5.58579 10.25 6 10.25Z" fill="currentColor" />
  </svg>

);

export default FilterIcon;
