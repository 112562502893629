import { Menu, withStyles } from '@material-ui/core';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';

import clsx from 'clsx';
import {
  SelectItem,
  Checkbox, FormControlLabel, CheckboxRadioSelect, Typography, Input, Radio,
} from '../index';
import { SearchIcon } from '../icons/index';
import { greyColor } from '../colors/index';

const RadioSelect = ({
  className,
  classes,
  value: controlledValue,
  onChange,
  radioItems,
  menuIsOpen,
  anchorElement,
  onClose,
  title,
  onClick,
  isSearchable,
  ...otherProps
}) => {
  const [searchInput, setSearchInput] = useState('');

  return (
    <CheckboxRadioSelect className={clsx(className, classes.checkboxSelect)} anchorElement={anchorElement} onClose={onClose} menuIsOpen={menuIsOpen} onClick={onClick} {...otherProps}>
      <Typography bold className={classes.checkboxSelect__title}>
        <div>{title}</div>
      </Typography>
      {/* <Input /> */}
      {isSearchable && (
      <div>
        <Input
          onKeyDown={(e) => {
            e.stopPropagation();
          }}

          classes={{
            root: clsx(classes.checkboxSelect__inputRoot),
          }}
          value={searchInput}
          onChange={(event) => {
            // event.preventDefault();
            setSearchInput(event.target.value);
          }}
          placeholder="Поиск"
          startAdornment={<SearchIcon className={classes.checkboxSelect__searchIcon} />}
        />
      </div>
      )}

      {radioItems.filter((item) => (searchInput === '' ? true : item.label.includes(searchInput))).map((checkboxesItem, index) => (
        <SelectItem className={classes.dropdown}>
          <FormControlLabel
            checked={controlledValue === (checkboxesItem.value)}
            control={<Radio />}
            label={checkboxesItem.label}
            onChange={(event) => {
              if (event.target.checked) {
                onChange(checkboxesItem.value);
              }
            }}
          />
          {/* <TextWithIcon className={selectItem.class} iconFirst label={selectItem.name} icon={React.cloneElement(selectItem.icon, { className: clsx(classes.menuIcon) })} /> */}
        </SelectItem>
      ))}
    </CheckboxRadioSelect>
  );
};

const style = () => ({
  checkboxSelect: {

  },
  checkboxSelect__title: {
    marginLeft: '15px',
    display: 'block',
    padding: '15px 0 15px 0',
  },
  checkboxSelect__searchIcon: {
    width: '12px',
    height: '12px',
    marginRight: '10px',
    color: greyColor[25],
  },
  checkboxSelect__inputRoot: {
    '&&&&&': {
      borderTop: `1px solid ${greyColor[10]}`,
      borderBottom: `1px solid ${greyColor[10]}`,
    },

    borderLeft: '0px',
    borderRight: '0px',
    borderRadius: '0',
  },
});

export default withStyles(style)(RadioSelect);
