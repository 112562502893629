import React from 'react';

export default (props) => {
  const { ellipseColor, questionMarkColor, ...otherProps } = props;
  return (
    <svg {...otherProps} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="5.99978" cy="6" rx="5.99978" ry="6" fill={ellipseColor} />
      <path d="M6.07473 2.85C4.79978 2.85 4.0498 3.6 4.0498 4.65H5.09977C5.09977 4.125 5.47475 3.75 5.99973 3.75C6.52471 3.75 6.8997 4.125 6.8997 4.575C6.8997 5.025 6.74971 5.25 6.14973 5.55C5.54975 5.925 5.32476 6.3 5.32476 6.975V7.275H6.37472V7.05C6.37472 6.6 6.52471 6.375 7.12469 6.075C7.72467 5.7 8.02466 5.25 8.02466 4.575C8.09966 3.525 7.27469 2.85 6.07473 2.85Z" fill={questionMarkColor} />
      <path d="M5.92473 8.1C5.54974 8.1 5.24976 8.4 5.24976 8.7C5.24976 9 5.54974 9.3 5.92473 9.3C6.29972 9.3 6.52471 9 6.52471 8.7C6.52471 8.4 6.29972 8.1 5.92473 8.1Z" fill={questionMarkColor} />
    </svg>
  );
};
