import { Tab as MuiTab, withStyles } from '@material-ui/core';
import * as React from 'react';
import classNames from 'classnames';
import blue from '../colors/blue';
import { Typography } from '../index';
import grey from '../colors/grey';

const styles = {
  root: {
    transition: 'background-color 0.2s ease',
    minHeight: 'auto',
    letterSpacing: '0px',

  },
  wrapper: {
    width: 'auto',
    marginLeft: '30px',
    marginRight: '30px',
    boxSizing: 'content-box',
    // display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '$root$labelIcon & svg': {
      width: '16px',
      height: '16px',
      marginBottom: '0px',
      marginRight: '8px',
      padding: '7px',
    },
    '$selected$root$labelIcon & svg': {
      backgroundColor: blue[15],
    },
  },
  selected: {
  },
  disabled: {

  },
  underlined: {
    '&$selected$root': {
      color: blue[100],
      fontWeight: '600',
    },
    '&$disabled': {
      '&$root': {
        color: grey[25],
        opacity: 1,
      },
    },
    '& $wrapper': {
      margin: '0px',
    },
    '&$root': {
      '&:hover:not($disabled):not($selected)': {
        color: blue[90],
      },
      paddingBottom: '22px',
      textTransform: 'none',
      minWidth: 'auto',
      padding: '0px',
    },
  },
  labelIcon: {
    minHeight: 'auto',

  },
  floating: {
    '&&&': {
      height: '30px',
      borderRadius: '3px',
      '&>span': {
        margin: '9px',
      },
    },

  },
  common: {
    '&$selected$root': {
      backgroundColor: 'white',
      color: blue[100],
      fontWeight: '600',
    },
    '&$disabled': {
      '&$root': {
        backgroundColor: grey[10],
        color: grey[25],
        opacity: 1,
      },
    },
    '&$root': {
      '&:hover:not($disabled):not($selected)': {
        backgroundColor: blue[15],
        color: blue[90],
      },
      backgroundColor: blue[30],
      borderRadius: '5px 5px 0px 0px',
      height: '40px',
      textTransform: 'none',
      minWidth: 'auto',
      padding: '0px',
    },
  },
};

const Tab = React.forwardRef((props, ref) => {
  const {
    label, floating, className, styleVariant = 'underlined', classes, ...others
  } = props;
  const {
    underlined, common, floating: floatingClass, ...otherClasses
  } = classes;
  return (<MuiTab ref={ref} className={classNames(classes[styleVariant], className, { [classes.floating]: floating })} label={<Typography>{label}</Typography>} disableRipple disableFocusRipple classes={otherClasses} {...others} />);
});

export default withStyles(styles)(Tab);
