import * as React from 'react';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import grey from '../colors/grey';

const styles = (theme) => ({
  title: theme.typography.title,
  subtitle: theme.typography.subtitle,
  common: theme.typography.common,
  commonBold: theme.typography.bold,
  detailed: theme.typography.detailed,
  normal: theme.typography.normal,
  disabled: theme.typography.disabled,
  white: theme.typography.white,
  root: theme.typography.root,
  bold: theme.typography.bold,
});

export const typographyDefaultTheme = {
  typography: {
    title: {
      fontWeight: 600,
      fontSize: '24px',
      lineHeight: '29px',
    },
    subtitle: {
      fontWeight: '600',
      fontSize: '18px',
      lineHeight: '21.78px',
    },
    common: {
      fontSize: '14px',
      lineHeight: '17px',
      fontWeight: '400',
    },
    bold: {
      fontWeight: '600',
    },
    detailed: {
      fontSize: '12px',
      lineHeight: '14.52px',
      fontWeight: '400',
    },
    normal: {
      color: grey['100'],
    },
    disabled: {
      color: grey['25'],
    },
    white: {
      color: 'white',
    },
    root: {
      fontFamily: 'Inter',
    // display: 'block'
    },
  },
};

const Typography = React.forwardRef((props, ref) => {
  const {
    bold,
    variant = 'common',
    style,
    classes,
    children,
    component,
    className,
    ...other
  } = props;

  const Component = component || 'span';

  return (
    <Component
      className={clsx(
        classes.root,
        classes[variant],
        classes[style],
        { [classes.bold]: bold },
        className,
      )}
      ref={ref}
      {...other}
    >
      {children}
    </Component>
  );
});

export default withStyles(styles, { name: 'MuiTypography', defaultTheme: typographyDefaultTheme })(Typography);
