import React from 'react';

const BotIcon = (props) => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M1.89327 11.3974C1.89327 8.83116 3.53732 7.15178 6.00339 7.15178C9.01748 7.15178 12.0133 7.15178 15.0274 7.15178C17.6213 7.15178 20.197 7.15178 22.791 7.15178V4.6044C21.8593 4.32136 21.1835 3.45336 21.1835 2.3778C21.1652 1.05694 22.1516 0.0191227 23.4121 0.000253283C24.6725 -0.0186162 25.6955 1.0192 25.7137 2.3212C25.7137 3.39676 25.0378 4.30249 24.1062 4.58553V7.13291C24.1245 7.13291 24.1245 7.13291 24.1427 7.13291C26.4444 7.15178 28.1067 8.8689 28.125 11.2465C28.125 14.6807 28.125 18.1149 28.1067 21.5492C28.1067 22.0775 28.0154 22.6247 27.8327 23.1153C27.2116 24.757 25.8599 25.6627 24.1062 25.6627C19.1923 25.6627 14.2967 25.6816 9.38283 25.6438C8.25026 25.6438 7.3369 25.9646 6.53314 26.8326C5.61978 27.8138 4.63335 28.7384 3.66519 29.663C3.06237 30.2291 2.20381 30.0593 1.94807 29.3234C1.875 29.1347 1.875 28.9271 1.875 28.7196C1.89327 25.7571 1.9298 22.7946 1.94807 19.8509C1.94807 18.6055 1.89327 17.3602 1.875 16.1148C1.875 14.5297 1.89327 12.9636 1.89327 11.3974ZM21.4757 16.3789C22.5535 16.3601 23.4669 15.4166 23.4669 14.2844C23.4669 13.1145 22.5718 12.1899 21.4392 12.1899C20.3249 12.1899 19.3933 13.1523 19.4115 14.2844C19.4298 15.4166 20.398 16.3789 21.4757 16.3789ZM12.0316 19.7C12.2873 20.6812 12.9632 21.3227 13.7487 21.7945C14.1871 22.0398 14.7717 22.002 15.2101 22.0587C16.3609 21.9643 17.1099 21.3605 17.7127 20.5114C17.8771 20.2849 18.0598 20.0396 18.1146 19.7943C18.1694 19.549 18.1876 19.1716 18.0598 18.9829C17.9502 18.7942 17.6213 18.7188 17.3656 18.681C16.9272 18.6433 16.4705 18.6621 16.0138 18.6621C15.6485 18.6621 15.3014 18.7188 14.9361 18.7188C14.1871 18.7376 13.4382 18.7188 12.6892 18.7188C12.0498 18.7376 11.8672 19.0584 12.0316 19.7ZM8.6156 16.3789C9.69337 16.3789 10.625 15.4355 10.625 14.3033C10.6433 13.1334 9.74817 12.2088 8.6156 12.2088C7.48304 12.2088 6.58794 13.1145 6.58794 14.2844C6.58794 15.4355 7.5013 16.3789 8.6156 16.3789Z" fill="#2A2B2B" />
  </svg>
);

export default BotIcon;
