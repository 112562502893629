import React from 'react';
import { withStyles } from '@material-ui/core';
import { typographyDefaultTheme } from '../Typography/index';
import { TableRow } from '../index';

const TableFooter = (props) => {
  const { children, classes } = props;

  return (
    <TableRow>
      {/* {children} */}
      {children?.map((child) => React.cloneElement(child, { className: classes.footer, ...child.props }))}
    </TableRow>
  );
};
const styles = () => ({
  footer: {
    ...typographyDefaultTheme.typography.bold,
    left: 0,
    bottom: 0,
    zIndex: 2,
    position: 'sticky',
    borderBottom: 0,
  },
});
export default withStyles(styles)(TableFooter);
