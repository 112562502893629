import React from 'react';

const DragAndDropIcon = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M12.4697 5.46967C12.7626 5.17678 13.2374 5.17678 13.5303 5.46967L15.5303 7.46967C15.8232 7.76256 15.8232 8.23744 15.5303 8.53033L13.5303 10.5303C13.2374 10.8232 12.7626 10.8232 12.4697 10.5303C12.1768 10.2374 12.1768 9.76256 12.4697 9.46967L13.9393 8L12.4697 6.53033C12.1768 6.23744 12.1768 5.76256 12.4697 5.46967Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M3.53033 5.46967C3.82322 5.76256 3.82322 6.23744 3.53033 6.53033L2.06066 8L3.53033 9.46967C3.82322 9.76256 3.82322 10.2374 3.53033 10.5303C3.23744 10.8232 2.76256 10.8232 2.46967 10.5303L0.46967 8.53033C0.176777 8.23744 0.176777 7.76256 0.46967 7.46967L2.46967 5.46967C2.76256 5.17678 3.23744 5.17678 3.53033 5.46967Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.5 2.25C6.91421 2.25 7.25 2.58579 7.25 3V13C7.25 13.4142 6.91421 13.75 6.5 13.75C6.08579 13.75 5.75 13.4142 5.75 13V3C5.75 2.58579 6.08579 2.25 6.5 2.25Z" fill="currentColor" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.5 2.25C9.91421 2.25 10.25 2.58579 10.25 3V13C10.25 13.4142 9.91421 13.75 9.5 13.75C9.08579 13.75 8.75 13.4142 8.75 13V3C8.75 2.58579 9.08579 2.25 9.5 2.25Z" fill="currentColor" />
  </svg>
);

export default DragAndDropIcon;
