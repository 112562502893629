import React from 'react';

export default (props) => (
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        d="M6.40007 10.4001H0.800009C0.587833 10.4001 0.384348 10.4844 0.234317 10.6344C0.0842863 10.7845 0 10.9879 0 11.2001L0 15.2002C0 15.4123 0.0842863 15.6158 0.234317 15.7659C0.384348 15.9159 0.587833 16.0002 0.800009 16.0002H6.40007C6.61224 16.0002 6.81573 15.9159 6.96576 15.7659C7.11579 15.6158 7.20008 15.4123 7.20008 15.2002V11.2001C7.20008 10.9879 7.11579 10.7845 6.96576 10.6344C6.81573 10.4844 6.61224 10.4001 6.40007 10.4001ZM5.60006 14.4002H1.60002L1.60002 12.0001H5.60006V14.4002ZM15.2002 0L9.6001 0C9.38793 0 9.18444 0.0842863 9.03441 0.234317C8.88438 0.384348 8.80009 0.587833 8.80009 0.800009V4.80005C8.80009 5.01223 8.88438 5.21571 9.03441 5.36574C9.18444 5.51577 9.38793 5.60006 9.6001 5.60006L15.2002 5.60006C15.4123 5.60006 15.6158 5.51577 15.7659 5.36574C15.9159 5.21571 16.0002 5.01223 16.0002 4.80005V0.800009C16.0002 0.587833 15.9159 0.384348 15.7659 0.234317C15.6158 0.0842863 15.4123 0 15.2002 0ZM14.4002 4.00004L10.4001 4.00004V1.60002L14.4002 1.60002V4.00004ZM15.2002 7.20008L9.6001 7.20008C9.38793 7.20008 9.18444 7.28436 9.03441 7.4344C8.88438 7.58443 8.80009 7.78791 8.80009 8.00009V15.2002C8.80009 15.4123 8.88438 15.6158 9.03441 15.7659C9.18444 15.9159 9.38793 16.0002 9.6001 16.0002L15.2002 16.0002C15.4123 16.0002 15.6158 15.9159 15.7659 15.7659C15.9159 15.6158 16.0002 15.4123 16.0002 15.2002L16.0002 8.00009C16.0002 7.78791 15.9159 7.58443 15.7659 7.4344C15.6158 7.28436 15.4123 7.20008 15.2002 7.20008ZM14.4002 14.4002H10.4001V8.8001H14.4002V14.4002ZM6.40007 0L0.800009 0C0.587833 0 0.384348 0.0842863 0.234317 0.234317C0.0842863 0.384348 0 0.587833 0 0.800009L0 8.00009C0 8.21226 0.0842863 8.41575 0.234317 8.56578C0.384348 8.71581 0.587833 8.8001 0.800009 8.8001H6.40007C6.61224 8.8001 6.81573 8.71581 6.96576 8.56578C7.11579 8.41575 7.20008 8.21226 7.20008 8.00009L7.20008 0.800009C7.20008 0.587833 7.11579 0.384348 6.96576 0.234317C6.81573 0.0842863 6.61224 0 6.40007 0ZM5.60006 7.20008L1.60002 7.20008L1.60002 1.60002L5.60006 1.60002L5.60006 7.20008Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" transform="matrix(1 0 0 -1 0 16)" />
      </clipPath>
    </defs>
  </svg>
);
