import React from 'react';
import clsx from 'clsx';
import { withStyles } from '@material-ui/core';
import { Typography } from '../index';
import { blueColor } from '../colors/index';

const Link = (props) => {
  const {
    children, classes, className, ...otherProps
  } = props;
  const { link, ...otherClasses } = classes;
  return (
    <Typography
      className={clsx(classes.link, className)}
      /* eslint-disable-next-line react/jsx-props-no-spreading */
      {...otherProps}
      classes={otherClasses}
    >
      {children}
    </Typography>
  );
};

const styles = () => ({
  link: {
    cursor: 'pointer',
    color: blueColor[100],
  },
});

export default withStyles(styles)(Link);
