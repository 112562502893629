import React from 'react';

const CorrectIcon = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path fillRule="evenodd" clipRule="evenodd" d="M0.1875 11.25C0.1875 10.9393 0.43934 10.6875 0.75 10.6875H11.25C11.5607 10.6875 11.8125 10.9393 11.8125 11.25C11.8125 11.5607 11.5607 11.8125 11.25 11.8125H0.75C0.43934 11.8125 0.1875 11.5607 0.1875 11.25Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.75943 0.384423C7.27199 -0.12814 8.10302 -0.128142 8.61558 0.384422L10.1799 1.94876C10.6925 2.46132 10.6925 3.29235 10.1799 3.80492L5.46025 8.52459C5.3718 8.61304 5.25639 8.66948 5.13227 8.685L2.13227 9.06C1.96003 9.08153 1.7875 9.02233 1.66476 8.89959C1.54202 8.77685 1.48282 8.60431 1.50435 8.43207L1.87935 5.43207C1.89486 5.30795 1.95131 5.19254 2.03976 5.10409L6.75943 0.384423ZM7.82009 1.17992C7.74686 1.10669 7.62815 1.10669 7.55492 1.17992L3.21434 5.5205C3.05709 5.67775 2.95675 5.88292 2.92917 6.10358L2.71036 7.85398L4.46077 7.63518C4.68143 7.6076 4.8866 7.50725 5.04384 7.35001L9.38443 3.00942C9.45765 2.9362 9.45765 2.81748 9.38443 2.74426L7.82009 1.17992Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.85205 4.89803L5.60205 2.64803L6.39755 1.85254L8.64755 4.10254L7.85205 4.89803Z" fill="white" />
    <defs>
      <clipPath id="clip0">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default CorrectIcon;
