import React from 'react';

const EyeIcon = (props) => (
  <svg {...props} width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 7.6875C16 7.6875 13.6 3.5625 8 3.5625C2.4 3.5625 0.1 7.59375 0 7.6875V7.78125V7.875C0 7.875 2 12 8 12C13.7 12 15.9 7.96875 16 7.875V7.6875ZM8 8.625C7 8.625 6.1 7.78125 6.1 6.84375C6.1 5.8125 7 4.96875 8 4.96875C9 4.96875 9.9 5.8125 9.9 6.75C9.9 7.78125 9 8.625 8 8.625ZM4.8 6.75C4.8 8.4375 6.3 9.84375 8 9.84375C9.8 9.84375 11.2 8.4375 11.2 6.75C11.2 6.1875 11.1 5.71875 10.8 5.25C12.7 5.90625 13.9 7.03125 14.3 7.6875C13.6 8.625 11.6 10.5937 7.9 10.5937C4.1 10.5937 2.2 8.71875 1.5 7.78125C2 7.21875 3.1 6 5.1 5.34375C4.9 5.8125 4.8 6.28125 4.8 6.75Z"
      fill="currentColor"
    />
    <path
      d="M7.9998 2.71875C8.3998 2.71875 8.6998 2.4375 8.6998 2.0625V0.65625C8.6998 0.28125 8.3998 0 7.9998 0C7.5998 0 7.2998 0.28125 7.2998 0.65625V2.0625C7.2998 2.4375 7.5998 2.71875 7.9998 2.71875Z"
      fill="currentColor"
    />
    <path
      d="M13.0004 3.5625C13.2004 3.5625 13.4004 3.46875 13.5004 3.375L14.5004 2.4375C14.8004 2.15625 14.8004 1.78125 14.5004 1.5C14.2004 1.21875 13.8004 1.21875 13.5004 1.5L12.5004 2.4375C12.2004 2.71875 12.2004 3.09375 12.5004 3.375C12.6004 3.46875 12.8004 3.5625 13.0004 3.5625Z"
      fill="currentColor"
    />
    <path
      d="M2.8002 3.375C2.9002 3.46875 3.1002 3.5625 3.3002 3.5625C3.5002 3.5625 3.7002 3.46875 3.8002 3.375C4.1002 3.09375 4.1002 2.71875 3.8002 2.4375L2.8002 1.5C2.5002 1.21875 2.1002 1.21875 1.8002 1.5C1.5002 1.78125 1.5002 2.15625 1.8002 2.4375L2.8002 3.375Z"
      fill="currentColor"
    />
  </svg>
);

export default EyeIcon;
