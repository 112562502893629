import React from 'react';
import clsx from 'clsx';
import { Typography, withStyles } from '@material-ui/core';
import {
  blueColor,
  CrossIcon,
  ExclamationMarkIcon,
  greenColor,
  greyColor,
  OkIcon,
  redColor,
} from '..';

const mapping = (severity) => {
  if (severity === 'success') {
    return <OkIcon />;
  }
  return <ExclamationMarkIcon />;
};

const AlertContents = ({
  classes, severity = 'info', title, children, onClickCross,
}) => (
  <div className={clsx(classes.root, classes[severity])}>
    {React.cloneElement(mapping(severity), { className: clsx(classes.leftIcon, classes.icon) })}
    <CrossIcon className={clsx(classes.rightIcon, classes.icon)} onClick={onClickCross} />
    <div className={classes.content}>
      <div className={classes.title}>
        <Typography bold>{title}</Typography>
      </div>
      <div className={classes.description}>
        <Typography variant="detailed">
          {children}
        </Typography>
      </div>
    </div>
  </div>
);
const styles = () => ({
  root: {
    wordSpacing: '-1px',
    padding: '20px',
    width: '400px',
    filter: 'drop-shadow(0px 2px 20px rgba(0, 36, 70, 0.07))',
    borderRadius: '5px',

  },
  error: {
    backgroundColor: redColor[10],
    '& $leftIcon': {
      color: redColor[100],
    },
  },
  info: {
    backgroundColor: blueColor[10],
    '& $leftIcon': {
      color: blueColor[100],
    },
  },
  success: {
    backgroundColor: greenColor[10],
    '& $leftIcon': {
      color: greenColor[10],
    },
  },
  icon: {
    position: 'absolute',
  },
  content: {
    margin: '0 31px 0 39px',
  },
  title: {
    marginBottom: '5px',
    marginTop: '4px',
  },
  description: {
    width: '295px',
  },
  icons: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  leftIcon: {
    width: '24px',
    height: '24px',
  },
  rightIcon: {
    right: '20px',
    color: greyColor[25],
  },
});
export default withStyles(styles)(AlertContents);
