import React from 'react';

export default (props) => (
  <svg {...props} width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.29502 5.21329L10.2761 1.2972C10.5746 1.0035 10.5746 0.513986 10.2761 0.22028C9.97749 -0.0734266 9.47986 -0.0734266 9.18128 0.22028L5.20024 4.13636L1.21919 0.22028C1.02014 0.0244755 0.522512 0.0244755 0.223934 0.318182C-0.0746446 0.611888 -0.0746446 1.1014 0.223934 1.3951L4.20498 5.31119L0.223934 9.22727C-0.0746446 9.52098 -0.0746446 10.0105 0.223934 10.3042C0.32346 10.4021 0.522512 10.5 0.721564 10.5C0.920616 10.5 1.11967 10.4021 1.21919 10.3042L5.20024 6.38811L9.18128 10.3042C9.2808 10.4021 9.47986 10.5 9.67891 10.5C9.87796 10.5 10.077 10.4021 10.1765 10.3042C10.4751 10.0105 10.4751 9.52098 10.1765 9.22727L6.29502 5.21329Z"
      fill="currentColor"
    />
  </svg>
);
