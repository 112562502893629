import React, { useState } from 'react';
import { TableCell as MuiTableCell, withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { greyColor } from '../colors';
import grey from '../colors/grey';
import { typographyDefaultTheme } from '../Typography';
import ArrowDownIcon from '../icons/ArrowDownIcon';

const style = (theme) => ({
  root: {
    borderBottom: '0.5px solid',
    backgroundColor: 'white',
    height: '60px',
    boxSizing: 'border-box',
    padding: 'initial',
    paddingLeft: '0px',
  },
  head: {
    borderBottomColor: greyColor[25],

    ...typographyDefaultTheme.typography.bold,
  },
  normalTwo: {
    backgroundColor: greyColor.background,
  },
  body: {
    borderBottomColor: greyColor[25],
  },
  disabled: {
    disabled$root: {
      backgroundColor: grey[10],
    },
  },
  alignLeft: {
    paddingLeft: '12px',
  },
  alignCenter: {
    '& $sortable': {
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
  },
  alignRight: {
    '& $sortable': {
      marginLeft: 'auto',
    },
  },
  sortable: {
    'user-select': 'none',
    '-webkit-user-select': 'none',
    '-moz-user-select': 'none',
    '-khtml-user-select': 'none',
    '-ms-user-select': 'none',
    cursor: 'pointer',
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    '&>svg': {
      marginRight: '5px',
    },
  },
  ascending: {
    transform: 'rotate(180deg)',
  },
  startAdornment: {
    display: 'flex',
    alignItems: 'center',
  },
});
const TableCell = React.forwardRef((props, ref) => {
  const {
    children,
    classes,
    className,
    align,
    startAdornment,
    sortable,
    onChangeSorting,
    sorting,
    onClickLabel,
    onClickSortableCell,
    ...otherProps
  } = props;
  const {
    normalTwo,
    disabled,
    startAdornment: startAdornmentClass,
    ascending: ascendingClass,
    sortable: sortableClass,
    ...otherClasses
  } = classes;

  const getChildrenWithArrows = (children) => {
    if (children) {
      return (
        <span onClick={(e) => { onClickLabel(e); onClickSortableCell?.(e); }} className={classes.sortable}>
          {sorting !== null
          && (
          <ArrowDownIcon
            className={clsx({ [classes.ascending]: sorting === 'ascending' })}
          />
          )}
          {children}
        </span>
      );
    }
    return undefined;
  };
  return (
    <MuiTableCell
      align={align || 'center'}
      className={clsx(className, { [classes.alignLeft]: align === 'left' })}
      {...otherProps}
      classes={otherClasses}
      ref={ref}
    >
      <div className={clsx({ [classes.startAdornment]: startAdornment })}>
        {startAdornment}
        {sortable ? getChildrenWithArrows(children) : children}
      </div>
    </MuiTableCell>
  );
});

export default withStyles(style, { name: 'AIsalesTableCell' })(TableCell);
