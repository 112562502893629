import React from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import {
  greenColor, greyColor, redColor, orangeColor,
} from '../colors/index';

const ProgressBar = ({ classes, percent, number }) => {
  const getColorClassName = (percent) => {
    // <60 - красный  <65 желтый  >65 зеленый
    if (percent < 60) {
      return classes.progressBar__bottomPartLeft_red;
    } if (percent < 65) {
      return classes.progressBar__bottomPartLeft_yellow;
    } if (percent >= 65) {
      return classes.progressBar__bottomPartLeft_green;
    }
  };
  return (
    <div>
      <div className={classes.progressBat__topPart}>
        <div>
          {percent}
          %
        </div>
        <div>{number}</div>
      </div>
      <div className={classes.progressBar__bottomPart}>
        <div
          style={{ width: `${percent}%` }}
          className={clsx(classes.progressBar__bottomPartLeft, getColorClassName(percent))}
        />
        <div
          style={{ width: `${100 - percent}%` }}
          className={clsx(
            classes.progressBar__bottomPartRight,
          )}
        />
      </div>
    </div>
  );
};

const styles = () => ({
  progressBar: {

  },
  progressBat__topPart: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  progressBar__bottomPart: {
    display: 'flex',
    height: '3px',
    borderRadius: '2px',
    width: '100%',
  },
  progressBar__bottomPartLeft: {
  },
  progressBar__bottomPartLeft_red: {
    backgroundColor: redColor[100],
  },
  progressBar__bottomPartLeft_green: {
    backgroundColor: greenColor[100],
  },
  progressBar__bottomPartLeft_yellow: {
    backgroundColor: orangeColor[100],
  },
  progressBar__bottomPartRight: {
    backgroundColor: greyColor[10],
  },
});
export default withStyles(styles)(ProgressBar);
