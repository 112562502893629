import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Typography from '../Typography';
import { TickIcon } from '../icons';
import { blueColor } from '../colors';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  stepHeader: {
    // display: "flex"
  },
  connectorLeft: {
    borderRight: `1px solid ${blueColor[100]}`,
    width: '100%',
  },
  connectorRight: {
    width: '100%',
  },
  connector: {
    display: 'flex',
    flex: '1',
  },
  title: {
    height: '40px',
    display: 'flex',
    marginBottom: '11px',
    alignItems: 'center',
  },
  number: {
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${blueColor[100]}`,
    color: blueColor[100],
    boxSizing: 'border-box',
    backgroundColor: 'white',
  },
  activeNumber: {
    backgroundColor: blueColor[100],
    color: 'white',
  },
  step: {
    display: 'flex',

  },
  leftPart: {
    display: 'flex',
    flexDirection: 'column',
  },
  rightPart: {
    marginLeft: '30px',
    flex: '1',
  },
  content: {
    marginBottom: '31px',
  },
  doneStep: {},
  tickIcon: {
    width: '14px',
    height: '10px',
  },

}));

export default function Stepper(props) {
  const classes = useStyles();
  const {
    activeStep,
    steps,
    bottom,
    className,
    allConnected,
  } = props;

  return (
    <div className={clsx(classes.root, className)}>
      {steps.map((step, index) => (
        <div className={classes.step} key={index}>
          <div className={classes.leftPart}>
            <div className={clsx(classes.number, { [classes.activeNumber]: activeStep === index + 1 })}>
              {index + 1 < activeStep ? <div><TickIcon /></div>
                : <Typography variant="subtitle">{index + 1}</Typography>}
            </div>
            {(steps[index + 1] && (index + 1 === activeStep || allConnected))
                        && (
                        <div className={classes.connector}>
                          <div className={classes.connectorLeft} />
                          <div className={classes.connectorRight} />
                        </div>
                        )}
          </div>
          <div className={classes.rightPart}>
            {step.title
                        && (
                        <div className={classes.title}>
                          <Typography variant="subtitle">{step.title}</Typography>
                        </div>
                        )}
            <div className={classes.content}>
              {step.component}
            </div>
            {index + 1 === steps.length && (
            <div>
              {bottom}
            </div>
            )}
          </div>
        </div>
      ))}
    </div>

  );
}
