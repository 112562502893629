import React from 'react';

const AuthlessIcon = (props) => (
  <svg
    x="0px"
    y="0px"
    viewBox="0 0 512.052 512.052"
    width="30px"
    style={{ 'enable-background': 'new 0 0 512.052 512.052' }}
    {...props}
  >
    <path
      style={{ fill: '#64B5F6' }}
      d="M467.526,428.926c28.8-30.9,44.5-69.1,44.5-108.9c0-49.8-24.6-94.9-64-126.9
	c-0.9,114.1-108.2,206.9-240,206.9h-89.2c34.5,56.9,104.6,96,185.2,96h192c6.5,0,12.3-3.9,14.8-9.9c2.5-6,1.1-12.9-3.5-17.4
	L467.526,428.926z"
    />
    <path
      style={{ fill: '#2196F3' }}
      d="M208.026,16.026c-114.7,0-208,78.9-208,176c0,39.8,15.7,77.9,44.5,108.9l-39.8,39.8
	c-4.6,4.6-6,11.5-3.5,17.4c2.5,6,8.3,9.9,14.8,9.9h192c114.7,0,208-78.9,208-176S322.726,16.026,208.026,16.026z"
    />
    <g>
      <path
        style={{ fill: '#FAFAFA' }}
        d="M304.026,144.026h-192c-8.8,0-16,7.2-16,16s7.2,16,16,16h192c8.8,0,16-7.2,16-16
		S312.826,144.026,304.026,144.026z"
      />
      <path
        style={{ fill: '#FAFAFA' }}
        d="M240.026,208.026h-128c-8.8,0-16,7.2-16,16s7.2,16,16,16h128c8.8,0,16-7.2,16-16
		S248.826,208.026,240.026,208.026z"
      />
    </g>
  </svg>

);

export default AuthlessIcon;
