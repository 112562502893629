import React from 'react';

const RefreshIcon = (props) => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0)">
      <path fillRule="evenodd" clipRule="evenodd" d="M10.4075 3.05492C9.25529 2.49396 7.94336 2.35297 6.69826 2.65628C5.45316 2.95958 4.35306 3.68816 3.58792 4.71618C2.82278 5.74421 2.44064 7.00715 2.50749 8.28691C2.57434 9.56668 3.08598 10.7829 3.95406 11.7256C4.82215 12.6684 5.99217 13.2783 7.26209 13.4503C8.53202 13.6222 9.82212 13.3453 10.9096 12.6674C11.9971 11.9894 12.8138 10.953 13.2185 9.73707C13.3493 9.34406 13.774 9.13151 14.167 9.26233C14.56 9.39315 14.7725 9.8178 14.6417 10.2108C14.1266 11.7583 13.0872 13.0774 11.7032 13.9403C10.3191 14.8031 8.67711 15.1555 7.06085 14.9367C5.44458 14.7179 3.95546 13.9415 2.85063 12.7417C1.7458 11.5419 1.09462 9.99395 1.00954 8.36516C0.924454 6.73637 1.41081 5.12899 2.38462 3.82059C3.35844 2.5122 4.75857 1.58492 6.34324 1.19889C7.92791 0.812865 9.59765 0.992315 11.0641 1.70626C12.51 2.41018 13.9992 3.83414 15.055 4.9955C15.3336 5.30199 15.311 5.77633 15.0045 6.05496C14.698 6.33359 14.2237 6.31101 13.9451 6.00452C12.9088 4.8647 11.5803 3.62589 10.4075 3.05492Z" fill="#2A2B2B" />
      <path fillRule="evenodd" clipRule="evenodd" d="M15.25 2C15.6642 2 16 2.33579 16 2.75V6.25C16 6.66421 15.6642 7 15.25 7H11.75C11.3358 7 11 6.66421 11 6.25C11 5.83579 11.3358 5.5 11.75 5.5H14.5V2.75C14.5 2.33579 14.8358 2 15.25 2Z" fill="#2A2B2B" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default RefreshIcon;
