import React from 'react';
import { withStyles } from '@material-ui/core';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const style = () => ({
  root: {
    width: '400px',
    padding: '0px',
    margin: '0px',
    '& .Toastify__toast': {
      padding: '0px',
      margin: '0px',
    },
    '& .Toastify__toast-body': {
      padding: '0px',
      margin: '0px',
    },
  },
});

const Alert = React.forwardRef((props, ref) => {
  const { classes } = props;
  return (
    <ToastContainer
      draggable={false}
      autoClose={5000}
      closeOnClick={false}
      ref={ref}
      className={classes.root}
      {...props}
    />
  );
});
export default withStyles(style)(Alert);
