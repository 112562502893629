import classNames from 'classnames';
import * as React from 'react';
import { ButtonBase } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import blue from '../colors/blue';
import grey from '../colors/grey';
import { Typography } from '../index';

const palette = {
  background: {
    primary: {
      color: blue['100'],
      hover: {
        color: blue['100'],
      },
      active: {
        color: blue['90'],
      },
    },
    secondary: {
      color: blue['30'],
      hover: {
        color: blue['30'],
      },
      active: {
        color: blue['20'],
      },
    },
    disabled: {
      color: grey['10'],
    },
  },
};

const defaultTheme = {
  button: {
    hover: {
      boxShadow: '0px 4px 10px rgba(4, 134, 254, 0.2)',
    },
    disabled: {
      '&$root': {
        backgroundColor: grey[10],
      },
    },
    primary: {
      backgroundColor: palette.background.primary.color,
      active: {
        backgroundColor: palette.background.primary.active.color,
      },
    },
    secondary: {
      backgroundColor: palette.background.secondary.color,
      active: {
        backgroundColor: palette.background.secondary.active.color,
      },
    },
  },
};

const typographyStyle = {
  primary: {
    style: 'white',

  },
  secondary: {
    style: 'normal',
  },
};

const Button = React.forwardRef((props, ref) => {
  const {
    children,
    classes,
    className,
    disabled,
    color = 'primary',
    component = 'button',
    size = 'medium',
    icon,
    ...other
  } = props;

  const Component = component || 'span';

  return (
    <ButtonBase
      ref={ref}
      disableTouchRipple
      className={classNames(
        classes.root,
        {
          [classes.disabled]: disabled,
          [classes[color]]: !disabled,
        },
        classes[size],
        { [classes.withIcon]: icon, [classes.justIcon]: !children && icon },
        className,
      )}
      ref={ref}
      component={component}
      disabled={disabled}
      {...other}
    >
      <Typography variant="common" style={disabled ? 'disabled' : typographyStyle[color].style}>
        {children}
        {icon}
      </Typography>
    </ButtonBase>
  );
});

const styles = (theme) => ({
  root: {
    transition: 'background-color 0.2s, box-shadow 0.2s, color 0.2s, border-color 0.2s',
    '&:hover': theme.button.hover,
    paddingLeft: '50px',
    paddingRight: '50px',
    height: '45px',
    borderRadius: '5px',
  },
  disabled: theme.button.disabled,
  primary: {
    '&:active': theme.button.primary.active,
    backgroundColor: theme.button.primary.backgroundColor,
  },
  secondary: {
    '&:active': theme.button.secondary.active,
    backgroundColor: theme.button.secondary.backgroundColor,
  },
  small: {
    height: '30px',
    '&&$justIcon': {
      width: '24px',
      height: '24px',
      padding: '0px',
      '& svg': {
        marginLeft: '0px',
      },
    },
    '&&$withIcon': {
      paddingLeft: '10px',
      paddingRight: '10px',
      '& svg': {
        marginLeft: '10px',
      },
    },
  },
  medium: {
    '&&&$justIcon': {
      width: '30px',
      height: '30px',
      padding: '0px',
      '& svg': {
        marginLeft: '0px',
      },
    },
    '&$withIcon': {
      paddingLeft: '15px',
      paddingRight: '15px',
      '& svg': {
        marginLeft: '15px',
      },
    },
  },
  justIcon: {
    '&& svg': {
      marginLeft: '0px',
    },
  },
  withIcon: {
    '& > .MuiTypography-root': {
      display: 'flex',
      alignItems: 'center',

    },

  },

});

export default withStyles(styles, { name: 'MuiButton', defaultTheme })(Button);
