import React from 'react';

export default (props) => (
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path d="M1.77778 2.22266C1.30628 2.22266 0.854097 2.40996 0.520699 2.74336C0.187301 3.07675 0 3.52894 0 4.00043L0 12.0004C0 12.4719 0.187301 12.9241 0.520699 13.2575C0.854097 13.5909 1.30628 13.7782 1.77778 13.7782H14.2222C14.6937 13.7782 15.1459 13.5909 15.4793 13.2575C15.8127 12.9241 16 12.4719 16 12.0004V4.00043C16 4.00043 16 2.22266 14.2222 2.22266H1.77778Z" fill="#FFAC33" />
      <path d="M0 4.44434H16V6.66656H0V4.44434Z" fill="#292F33" />
      <path d="M1.77783 8.44434H14.2223V11.111H1.77783V8.44434Z" fill="#F4F7F9" />
      <path d="M8.44475 10.6665C7.68787 10.6665 7.40431 10.1287 7.34742 9.86601C7.10431 9.88423 6.86742 10.0007 6.5452 10.2056C6.2052 10.4216 5.81987 10.6665 5.33364 10.6665C4.80164 10.6665 4.47853 10.3665 4.44475 9.77756C4.44342 9.75267 4.46164 9.69401 4.45409 9.69401C3.62831 9.69401 3.03098 10.4767 3.0252 10.4847C2.9908 10.5318 2.94742 10.5717 2.89755 10.6021C2.84768 10.6324 2.79231 10.6526 2.73461 10.6615C2.67692 10.6704 2.61804 10.6678 2.56135 10.6538C2.50467 10.6399 2.4513 10.6149 2.40431 10.5802C2.35723 10.5457 2.31741 10.5023 2.28713 10.4523C2.25685 10.4024 2.23669 10.3471 2.22782 10.2894C2.21895 10.2317 2.22153 10.1728 2.23541 10.1161C2.2493 10.0594 2.27422 10.006 2.30875 9.9589C2.34298 9.91223 3.16031 8.88867 4.44475 8.88867C5.30564 8.88867 5.35498 9.49978 5.36831 9.72778L5.37142 9.77623C5.58742 9.76112 5.81187 9.61889 6.0692 9.45489C6.49631 9.18378 7.02609 8.84556 7.73587 9.02734C8.12298 9.12645 8.18342 9.48556 8.20653 9.62112C8.21231 9.65489 8.22076 9.70556 8.22831 9.72156C8.23142 9.72156 8.2932 9.76201 8.48476 9.77934C8.8492 9.81267 9.24964 9.65534 9.67364 9.48912C10.1128 9.31667 10.567 9.13889 11.0563 9.13889C12.5625 9.13889 13.175 9.86556 13.2399 9.94823C13.2772 9.99405 13.305 10.0469 13.3217 10.1036C13.3383 10.1603 13.3435 10.2197 13.3369 10.2785C13.3303 10.3372 13.3121 10.394 13.2833 10.4456C13.2545 10.4972 13.2156 10.5426 13.1691 10.579C13.1225 10.6154 13.0692 10.6421 13.0122 10.6577C12.9551 10.6732 12.8956 10.6773 12.837 10.6695C12.7784 10.6618 12.722 10.6424 12.6709 10.6126C12.6199 10.5828 12.5754 10.543 12.5399 10.4958C12.5292 10.4838 12.1265 10.0273 11.0568 10.0273C10.7354 10.0273 10.3776 10.1673 9.99853 10.3158C9.55009 10.4918 8.91675 10.6665 8.44475 10.6665Z" fill="#8899A6" />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
