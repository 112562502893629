import React from 'react';

const ArrowDownIcon = (props) => (
  <svg {...props} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8 2.5C8.41421 2.5 8.75 2.82119 8.75 3.21739V12.7826C8.75 13.1788 8.41421 13.5 8 13.5C7.58579 13.5 7.25 13.1788 7.25 12.7826V3.21739C7.25 2.82119 7.58579 2.5 8 2.5Z" fill="#2A2B2B" />
    <path fillRule="evenodd" clipRule="evenodd" d="M3.46967 8.71967C3.76256 8.42678 4.23744 8.42678 4.53033 8.71967L8 12.1893L11.4697 8.71967C11.7626 8.42678 12.2374 8.42678 12.5303 8.71967C12.8232 9.01256 12.8232 9.48744 12.5303 9.78033L8.53033 13.7803C8.23744 14.0732 7.76256 14.0732 7.46967 13.7803L3.46967 9.78033C3.17678 9.48744 3.17678 9.01256 3.46967 8.71967Z" fill="#2A2B2B" />
  </svg>
);

export default ArrowDownIcon;
