const grey = {
  100: '#2A2B2B',
  80: '#6A6B6B',
  50: '#949595',
  25: '#CACACA',
  10: '#EAEBEB',
  background: '#F2F6F9',
  background2: '#1B1B39',
};

export default grey;
