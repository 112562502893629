import React from 'react';
// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.40001 1.5H13.3C13.9 1.5 14.5 1.9 14.5 2.4V13.6C14.5 14.1 14 14.5 13.3 14.5H7.40001C7.00001 14.5 6.60001 14.8 6.60001 15.3C6.70001 15.7 7.00001 16 7.40001 16H13.3C14.8 16 16 14.9 16 13.6V2.4C16 1.1 14.8 0 13.3 0H7.40001C7.00001 0 6.70001 0.3 6.70001 0.8C6.70001 1.3 7.00001 1.5 7.40001 1.5Z"
      fill="currentColor"
    />
    <path
      d="M0.8 8.80005H9L6.9 10.9C6.6 11.2 6.6 11.7 6.9 12C7.2 12.3 7.7 12.3 8 12L11.5 8.50005C11.8 8.20005 11.8 7.70005 11.5 7.40005L8 4.00005C7.8 3.90005 7.6 3.80005 7.4 3.80005C7.2 3.80005 7.1 3.90005 6.9 4.00005C6.6 4.30005 6.6 4.80005 6.9 5.10005L9.1 7.30005H0.8C0.3 7.30005 0 7.60005 0 8.10005C0 8.50005 0.3 8.80005 0.8 8.80005Z"
      fill="currentColor"
    />
  </svg>
);
