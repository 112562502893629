import React from 'react';

const FilledExclamationMarkIcon = (props) => (
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="8" fill="currentColor" />
    <path d="M8 16C12.4 16 16 12.4 16 8C16 3.6 12.4 0 8 0C3.6 0 0 3.6 0 8C0 12.4 3.6 16 8 16ZM8 1.5C11.6 1.5 14.5 4.4 14.5 8C14.5 11.6 11.6 14.5 8 14.5C4.4 14.5 1.5 11.6 1.5 8C1.5 4.4 4.4 1.5 8 1.5Z" fill="currentColor" />
    <path d="M8.00039 5.59961C8.50039 5.59961 8.90039 5.19961 8.90039 4.79961C8.90039 4.39961 8.50039 3.99961 8.00039 3.99961C7.50039 3.99961 7.20039 4.39961 7.20039 4.79961C7.20039 5.19961 7.60039 5.59961 8.00039 5.59961Z" fill="white" />
    <path d="M7.2002 12.2002H8.7002V6.4002H7.2002L7.2002 12.2002Z" fill="white" />
  </svg>
);

export default FilledExclamationMarkIcon;
