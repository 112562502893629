import React from 'react';

export default (props) => (
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.3354 7.20208C11.3292 6.40227 11.9255 5.30253 11.9255 3.90286C11.9255 1.80335 10.1366 0.103753 8.04969 0.0037761C5.76398 -0.0962003 3.97516 1.80335 3.97516 4.00283C3.97516 5.30253 4.57143 6.40227 5.56522 7.20208C2.28571 8.20184 0 11.2011 0 14.8003V15.3002C0 15.7001 0.298137 16 0.795031 16H15.205C15.6025 16 16 15.7001 16 15.3002V14.8003C15.9006 11.1012 13.6149 8.20184 10.3354 7.20208ZM5.46584 4.00283C5.46584 2.60316 6.55901 1.50342 7.95031 1.50342C9.34161 1.50342 10.4348 2.60316 10.4348 4.00283C10.4348 5.4025 9.34161 6.50224 7.95031 6.50224C6.55901 6.50224 5.46584 5.4025 5.46584 4.00283ZM7.75155 8.30182C11.3292 8.20184 14.2112 10.9012 14.4099 14.5004H1.49068C1.68944 11.1012 4.37267 8.40179 7.75155 8.30182Z"
      fill="currentColor"
    />
  </svg>
);
