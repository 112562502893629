import MuiPagination from '@material-ui/lab/Pagination';
import React from 'react';
import { PaginationItem } from '@material-ui/lab';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { blueColor } from '../colors/index';
import { typographyDefaultTheme } from '../Typography/index';

const styles = () => ({
  itemEllipsis: {
    width: '30px',
    height: '30px',
    borderRadius: '5px',
  },
  centralizedHorizontally: {
    display: 'flex',
    justifyContent: 'center',
  },
  centralizedVertically: {
    display: 'flex',
    alignItems: 'center',
  },
  itemRoot: {
    ...typographyDefaultTheme.typography.detailed,
    ...typographyDefaultTheme.typography.bold,
    backgroundColor: blueColor[10],
    '&:hover': {
      '&:not($selected)': {
        backgroundColor: blueColor[20],
        color: blueColor[90],
      },
    },
  },
  itemSelected: {
    color: 'white',
    '&&&&:hover': {
      backgroundColor: blueColor[100],
      color: 'white',
    },
    '&&': {
      backgroundColor: blueColor[100],
    },
  },
});
const Pagination = React.forwardRef((props, ref) => {
  const { classes } = props;
  return (
    <MuiPagination
      renderItem={(item) => (
        <PaginationItem
          {...item}
          classes={{
            ...item.classes,
            root: classes.itemRoot,
            selected: classes.itemSelected,
            ellipsis: clsx(classes.itemEllipsis,
              classes.centralizedVertically,
              classes.centralizedHorizontally),
          }}
          disableTouchRipple
        />
      )}
      shape="rounded"
      {...props}
    />
  );
});
export default withStyles(styles)(Pagination);
