import React from 'react';

const FilterBlueIcon = (props) => (
  <svg width="78" height="74" viewBox="0 0 78 74" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M78 11V15H0V11H78Z" fill="#0486FE" />
    <path d="M78 59V63H0V59H78Z" fill="#0486FE" />
    <path d="M78 35V39H0V35H78Z" fill="#0486FE" />
    <path d="M21.0024 2.80957C26.6456 2.80957 31.2275 7.37614 31.2275 13.0005C31.2275 18.6249 26.6456 23.1915 21.0024 23.1915C15.3592 23.1915 10.7773 18.6249 10.7773 13.0005C10.7773 7.37614 15.3592 2.80957 21.0024 2.80957Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M21 5.61807C16.8485 5.61807 13.5499 8.96803 13.5499 13C13.5499 17.032 16.8485 20.3819 21 20.3819C25.1515 20.3819 28.4501 17.032 28.4501 13C28.4501 8.96803 25.1515 5.61807 21 5.61807ZM8 13C8 5.78318 13.8651 0 21 0C28.1349 0 34 5.78318 34 13C34 20.2168 28.1349 26 21 26C13.8651 26 8 20.2168 8 13Z" fill="#0486FE" />
    <path d="M23.0024 50.8096C28.6456 50.8096 33.2275 55.3761 33.2275 61.0005C33.2275 66.6249 28.6456 71.1915 23.0024 71.1915C17.3592 71.1915 12.7773 66.6249 12.7773 61.0005C12.7773 55.3761 17.3592 50.8096 23.0024 50.8096Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M23 53.6181C18.8485 53.6181 15.5499 56.968 15.5499 61C15.5499 65.032 18.8485 68.3819 23 68.3819C27.1515 68.3819 30.4501 65.032 30.4501 61C30.4501 56.968 27.1515 53.6181 23 53.6181ZM10 61C10 53.7832 15.8651 48 23 48C30.1349 48 36 53.7832 36 61C36 68.2168 30.1349 74 23 74C15.8651 74 10 68.2168 10 61Z" fill="#0486FE" />
    <path d="M59.9985 26.8105C65.6417 26.8105 70.2236 31.3771 70.2236 37.0015C70.2236 42.6259 65.6417 47.1925 59.9985 47.1925C54.3553 47.1925 49.7734 42.6259 49.7734 37.0015C49.7734 31.3771 54.3553 26.8105 59.9985 26.8105Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M60 29.6181C55.8485 29.6181 52.5499 32.968 52.5499 37C52.5499 41.032 55.8485 44.3819 60 44.3819C64.1515 44.3819 67.4501 41.032 67.4501 37C67.4501 32.968 64.1515 29.6181 60 29.6181ZM47 37C47 29.7832 52.8651 24 60 24C67.1349 24 73 29.7832 73 37C73 44.2168 67.1349 50 60 50C52.8651 50 47 44.2168 47 37Z" fill="#0486FE" />
  </svg>
);

export default FilterBlueIcon;
