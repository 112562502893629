import React from 'react';

const StarIcon = (props) => (
  <svg {...props} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 1.12978L9.78644 4.74949C9.9321 5.04464 10.2137 5.24921 10.5394 5.29654L14.534 5.87698L11.6435 8.69454C11.4078 8.92428 11.3002 9.25528 11.3559 9.57967L12.0382 13.5581L8.46534 11.6798C8.17402 11.5266 7.82598 11.5266 7.53466 11.6798L3.96178 13.5581L4.64414 9.57967C4.69978 9.25528 4.59223 8.92428 4.35654 8.69454L1.46603 5.87698L5.46062 5.29654C5.78634 5.24921 6.0679 5.04464 6.21356 4.74949L8 1.12978Z"
      stroke="currentColor"
    />
  </svg>
);
export default StarIcon;
