import React from 'react';

const LockIcon = (props) => (
  <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3.85185 6.8651C3.13593 6.8651 2.55556 7.50412 2.55556 8.2924V12.8597C2.55556 13.648 3.13593 14.287 3.85185 14.287H12.1481C12.8641 14.287 13.4444 13.648 13.4444 12.8597V8.2924C13.4444 7.50412 12.8641 6.8651 12.1481 6.8651H3.85185ZM1 8.2924C1 6.55819 2.27682 5.15234 3.85185 5.15234H12.1481C13.7232 5.15234 15 6.55819 15 8.2924V12.8597C15 14.594 13.7232 15.9998 12.1481 15.9998H3.85185C2.27682 15.9998 1 14.594 1 12.8597V8.2924Z" fill="#0486FE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.38439 1.62712C5.64093 1.62712 5.03824 2.23419 5.03824 2.98305V6.23729H3.42285V2.98305C3.42285 1.33556 4.74878 0 6.38439 0H9.61516C11.2508 0 12.5767 1.33556 12.5767 2.98305V6.23729H10.9613V2.98305C10.9613 2.23419 10.3586 1.62712 9.61516 1.62712H6.38439Z" fill="#0486FE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.65381 9.76316C6.65381 9.0143 7.2565 8.40723 7.99996 8.40723C8.74342 8.40723 9.34612 9.0143 9.34612 9.76316C9.34612 10.512 8.74342 11.1191 7.99996 11.1191C7.2565 11.1191 6.65381 10.512 6.65381 9.76316ZM7.99996 10.0343C8.14865 10.0343 8.26919 9.91293 8.26919 9.76316C8.26919 9.61339 8.14865 9.49197 7.99996 9.49197C7.85127 9.49197 7.73073 9.61339 7.73073 9.76316C7.73073 9.91293 7.85127 10.0343 7.99996 10.0343Z" fill="#0486FE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.19238 12.0225V10.5762H8.80777V12.0225C8.80777 12.4219 8.44615 12.7457 8.00008 12.7457C7.554 12.7457 7.19238 12.4219 7.19238 12.0225Z" fill="#0486FE" />
  </svg>
);
export default LockIcon;
