import * as React from 'react';
import { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core';
import clsx from 'clsx';
import { blueColor, SelectItem, TextWithIcon } from '../index';
import blue from '../colors/blue';

const style = (theme) => ({
  root: {
    overflowX: 'hidden',
    width: '100%',
    height: '50px',
    '$root&': {
      paddingLeft: '30px',
    },
    '&&&&&:hover': {
      color: 'pink',
      backgroundColor: 'transparent',
    },

  },
  subItem: {
    height: '40px',
  },
  mainItem: {},
  mainItemWrapper: {
    position: 'relative',
    marginBottom: '10px',
    '&:hover $root:not(.Mui-disabled) $mainItemIcon': {
      backgroundColor: blue[90],
      color: 'white',
    },
    '& + li': {
      marginTop: '-5px',
    },
  },
  selectedIndicator: {
    position: 'absolute',
    width: '6px',
    height: '100%',
    borderRadius: '0px 3px 3px 0px',
    backgroundColor: blue[100],
    zIndex: '5',
    display: 'none',

  },
  selected: {
    paddingLeft: '30px',
    '$opened $root&': {
      backgroundColor: blue[15],
    },
    '$subItem&': {
      borderLeft: 'none',
    },
    '$mainItem& $iconWrapper': {
      backgroundColor: blue[100],
      color: 'white',
    },
  },
  iconWrapper: {
    padding: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '30px',
    width: '30px',
    borderRadius: '3px',
  },
  mainItemIcon: {
    transition: 'color 0.2s, background-color 0.3s',
  },
  subItemIcon: {},
  opened: {
    '& $selectedIndicator': {
      display: 'initial',
    },
    '&&&:hover $root:not(.Mui-disabled) $mainItemIcon': {
      backgroundColor: blue[90],
      color: 'white',
    },
    '& li:hover:not($selected)': {
      backgroundColor: 'transparent',
    },
    '&&&&&&& li:hover': {
      backgroundColor: blueColor[15],
    },
    marginBottom: '10px',
  },
  textWithIcon: {
    width: '130px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
});

const MainMenuItem = React.forwardRef((props) => {
  const {
    text,
    icon,
    classes,
    disabled,
    expandedHorizontally,
    expanded,
    subItems,
    onExpandedVertically,
    location: locationProps,
    link,
    history,
    regex,
    ...otherProps
  } = props;
  const isSelected = (location) => {
    if (location) {
      const oneOfSubmenusLocation = subItems?.find((subItem) => (subItem.regex ? new RegExp(subItem.regex).test(location.pathname) : subItem.link === location.pathname));
      const thisLinkLocation = (regex ? new RegExp(regex).test(location.pathname) : (link) === (location.pathname));

      return Boolean(Boolean(oneOfSubmenusLocation) | thisLinkLocation);
    }
  };

  const [location, setLocation] = useState(locationProps);

  function handleClick(link, subItem) {
    if (subItem) {
      if (!expandedHorizontally) {
        return;
      }
    }
    history.push(link);
    setExpandedVertically(expanded || true);
  }

  history.listen((location, action) => {
    setExpandedVertically(isSelected(location));
    setLocation(location);
  });

  useEffect(() => {
    setExpandedVertically(isSelected(location));
    setLocation(location);
  }, []);

  const [expandedVertically, setExpandedVertically] = useState(expanded | isSelected(location));
  const { textWithIcon: textWithIconClass, ...otherClasses } = classes;

  const itemIcon = (icon) => <div className={clsx(classes.iconWrapper, classes.mainItemIcon)}>{icon}</div>;
  const subItemIcon = () => <div className={clsx(classes.iconWrapper, classes.subItemIcon)} />;
  const generalItem = ({
    subItem, text, icon, link, selected, disabled,
  }) => (
    <SelectItem
      disabled={disabled}
      selected={selected}
      onClick={() => {
        handleClick(link, subItem);
      }}
      className={clsx({ [classes.selected]: selected }, { [classes.subItem]: subItem }, { [classes.mainItem]: !subItem })}
      classes={{ root: classes.root }}
      {...props}
    >

      <TextWithIcon classes={{ label: classes.textWithIcon }} iconFirst label={expandedHorizontally ? text : ''} icon={icon} />
    </SelectItem>
  );

  return (
    <div className={clsx({ [classes.opened]: expandedHorizontally })}>
      <div className={classes.mainItemWrapper}>
        {isSelected(location) && <div className={classes.selectedIndicator} />}
        {generalItem({
          icon: itemIcon(icon),
          disabled,
          selected: isSelected(location),
          link,
          text,
        })}
      </div>
      {(subItems && expandedVertically && expandedHorizontally) && subItems.map((subItem) => (
        generalItem({
          icon: subItemIcon(icon),
          link: subItem.link,
          disabled: subItem.disabled,
          selected: (subItem.regex ? new RegExp(subItem.regex).test(location.pathname) : (subItem.link) === (location.pathname)),
          text: subItem.text,
          subItem: true,
        })
      ))}
    </div>
  );
});

export default withStyles(style)(MainMenuItem);
