import { FormControlLabel as MuiFormControlLabel, withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { useState } from 'react';
import * as React from 'react';
import { Switch } from '../index';
import { typographyDefaultTheme } from '../Typography';

const defaultTheme = {
  label: {
    ...typographyDefaultTheme.typography.common,
    ...typographyDefaultTheme.typography.normal,
  },
  switchUnchecked: {
    '& $label': {
      color: 'red',
    },
  },
  switchChecked: {

  },
  switchDisabled: {
    '& $disabled$label': {
      color: typographyDefaultTheme.typography.normal.color,
    },
    backgroundColor: 'transparent',
  },
  root: {
  },

  disabled: {
    '&$root': {
      backgroundColor: 'transparent',
      '&$label': {
        ...typographyDefaultTheme.typography.disabled,
      },
    },

  },
};

const styles = (theme) => (theme);

const FormControlLabel = (props) => {
  const {
    onChange, classes, className, control, disabled, ...others
  } = props;
  const propChecked = props.checked;
  const {
    switchChecked, switchDisabled, switchUnchecked, ...otherClasses
  } = classes;
  const isSwitch = (control.type === Switch);
  const [checked, setChecked] = useState(propChecked);
  return (
    <MuiFormControlLabel
      classes={{ ...otherClasses }}
      className={classNames(isSwitch ? disabled ? classes.switchDisabled : checked ? classes.switchChecked : classes.switchUnchecked : undefined, className)}
      onChange={(event) => {
        if (onChange) {
          onChange(event);
        }
        setChecked(event.target.checked);
      }}
      control={control}
      disabled={disabled}
      {...others}
    />
  );
};
export default withStyles(styles, { name: 'MuiControlLabel', defaultTheme })(FormControlLabel);
