import React from 'react';

const CodeIcon = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8.25 2.25V8.25H2.25V2.25H8.25ZM8.7 0H1.8C0.75 0 0 0.75 0 1.8V8.7C0 9.75 0.75 10.5 1.8 10.5H8.7C9.75 10.5 10.5 9.75 10.5 8.7V1.8C10.5 0.75 9.75 0 8.7 0Z" fill="#0486FE" />
    <path d="M8.25 15.75V21.75H2.25V15.75H8.25ZM8.7 13.5H1.8C0.75 13.5 0 14.25 0 15.3V22.2C0 23.25 0.75 24 1.8 24H8.7C9.75 24 10.5 23.25 10.5 22.2V15.3C10.5 14.25 9.75 13.5 8.7 13.5Z" fill="#0486FE" />
    <path d="M21.75 2.25V8.25H15.75V2.25H21.75ZM22.2 0H15.3C14.25 0 13.5 0.75 13.5 1.8V8.7C13.5 9.75 14.25 10.5 15.3 10.5H22.2C23.25 10.5 24 9.75 24 8.7V1.8C24 0.75 23.25 0 22.2 0Z" fill="#0486FE" />
    <path d="M24 19.8H19.8V24H14.55C14.2715 24 14.0045 23.8894 13.8075 23.6925C13.6106 23.4956 13.5 23.2285 13.5 22.95V14.55V19.8H15.6V21.9H17.7V15.6H15.6V13.5H14.55H17.7V15.6H19.8V17.7H21.9V13.5H22.95C23.2285 13.5 23.4955 13.6106 23.6925 13.8075C23.8894 14.0045 24 14.2715 24 14.55V19.8ZM24 21.9V22.95C24 23.2285 23.8894 23.4956 23.6925 23.6925C23.4955 23.8894 23.2285 24 22.95 24H21.9V21.9H24Z" fill="#0486FE" />
  </svg>

);
export default CodeIcon;
