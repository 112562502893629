import React from 'react';

const ClosedEyeIcon = (props) => (
  <svg {...props} width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.2 2.85916C8.6 2.85916 8.9 2.56338 8.9 2.16901V0.690141C8.8 0.295775 8.5 0 8.2 0C7.8 0 7.5 0.295775 7.5 0.690141V2.16901C7.5 2.56338 7.8 2.85916 8.2 2.85916Z"
      fill="currentColor"
    />
    <path
      d="M13.1 3.74644C13.3 3.74644 13.5 3.64784 13.6 3.54925L14.6 2.56334C14.9 2.26756 14.9 1.8732 14.6 1.57742C14.3 1.28165 13.9 1.28165 13.6 1.57742L12.6 2.56334C12.3 2.85911 12.3 3.25348 12.6 3.54925C12.8 3.64784 12.9 3.74644 13.1 3.74644Z"
      fill="currentColor"
    />
    <path
      d="M13.4 12.9154C13.3 12.9154 13.3 12.8169 13.3 12.8169L2.2 1.57741L2 1.38023C1.8 1.28164 1.5 1.38023 1.3 1.57741C1 1.7746 1 2.26755 1.3 2.56333L3.5 4.83094C1.1 6.01403 0.1 7.88727 0 7.98587V8.08446V8.18305C0 8.18305 2 12.5211 8 12.5211C8.9 12.5211 9.9 12.4225 10.7 12.1267L12.3 13.8028C12.4 13.9014 12.6 13.9999 12.8 13.9999C13 13.9999 13.2 13.9014 13.3 13.8028C13.5 13.507 13.5 13.2112 13.4 12.9154ZM7.6 8.87319C6.9 8.7746 6.4 8.18305 6.2 7.49291L7.6 8.87319ZM8 11.0422C4.2 11.0422 2.3 9.07037 1.6 8.08446C2.1 7.49291 3 6.50699 4.5 5.81685L4.9 6.21122C4.8 6.50699 4.8 6.80277 4.8 7.09854C4.8 8.87319 6.3 10.3521 8 10.3521C8.3 10.3521 8.5 10.3521 8.8 10.2535L9.5 10.9436C9 11.0422 8.5 11.0422 8 11.0422Z"
      fill="currentColor"
    />
    <path
      d="M16 7.98586C16 7.98586 13.6 3.64783 8 3.64783C7.3 3.64783 6.7 3.74642 6 3.84501L7.4 5.22529C7.6 5.22529 7.8 5.1267 8 5.1267C9 5.1267 9.9 6.01402 9.9 6.99994C9.9 7.19712 9.9 7.39431 9.8 7.59149L10.8 8.5774C11.1 8.08445 11.3 7.4929 11.3 6.99994C11.3 6.40839 11.2 5.91543 10.9 5.42248C12.8 6.11262 14 7.29572 14.4 7.98586C14.1 8.38022 13.3 9.26755 12.1 9.95769L13.1 10.9436C15.1 9.66191 15.9 8.18304 16 8.08445V7.98586Z"
      fill="currentColor"
    />
  </svg>
);

export default ClosedEyeIcon;
