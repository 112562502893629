import React, { useState } from 'react';
import { TableHead as MuiTableHead, withStyles } from '@material-ui/core';

const style = (theme) => ({

});
const TableHead = React.forwardRef((props, ref) => {
  const {
    sortable, children, onChangeSorting, sortedColumnIdx, sorting,
  } = props;
  const sortingArray = [null, 'ascending', 'descending'];

  const getSortableChildren = (children) => {
    if (children) {
      if (Array.isArray(children)) {
        return children.map((child, index) => React.cloneElement(child, {

          sortable: true,
          sorting: sortedColumnIdx === index ? sorting : null,
          onClickLabel: () => {
                        onChangeSorting?.(sortingArray[(sortingArray.indexOf(sorting) + 1) % 3], index);
                        child.props?.onClickLabel?.();
          },
          ...child.props,
        }));
      }
      // else {
      //     return React.cloneElement(children, {...children.props, sortable: true, onChangeSorting: (sorting) => {onChangeSorting?.(sorting, 0); setSortedColumnIdx(0)}})
      // }
    } else {
      return undefined;
    }
  };
  return <MuiTableHead ref={ref} {...props} children={sortable ? getSortableChildren(children) : children} />;
});

export default withStyles(style)(TableHead);
